import React, { useState, useRef } from "react";
import "../../assets/css/session.css";

export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();

  const [questionNumber, setQuestionNumber] = useState(0);
  const [Activitystarted, setActivitystarted] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [SelectedScreen, setSelectedScreen] = useState("");
  const [ClickedNext, setClickedNext] = useState(false);
  const [dragging, setDragging] = useState(true);
  const [divtochangeContent, setDivtochangeContent] = useState(false);
  const [ValueDropdown, setValueDropdown] = useState("");
  const [SelectedFontsize, setSelectedFontsize] = useState("");
  const [Finalscreen, setFinalscreen] = useState(true);
  const [selectedpopup, setSelectedpopup] = useState(false);

  // Handle dropdown change event
  const handleColorChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedColor(event.target.value);
    }
  };
  const handleAttributeChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedAttribute(event.target.value);
    }
  };
  const handleScreenChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedScreen(event.target.value);
    }
  };
  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", e.target.id);
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData("text/plain");
    const droppedItem = document.getElementById(droppedItemId);
    // Update the content of divtochangeContent here based on the drop event
    setDivtochangeContent(true);
    e.target.appendChild(droppedItem);
    setDragging(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const colorMap = {
    Red: "red",
    Green: "green",
    Blue: "blue",
    Yellow: "yellow",
  };
  const screenMap = {
    one: "../images/NewActivityImages/mobile3.jpg",
    two: "../images/NewActivityImages/mobile4.jpg",
    three: "../images/NewActivityImages/mobile5.jpg",
    four: "../images/NewActivityImages/Project.jpg",
  };
  const labelMap = {
    "16px": "red",
    "16px": "green",
    "20px": "blue",
    "24px": "yellow",
    "16px": "yellow",
  };
  const handleFontsize = (event) => {
    const selectedOption = event.target.value;
    setSelectedFontsize(selectedOption);
  };

  const Goout = () => {
    setFinalscreen(false);
  };

  const divStyle = {
    backgroundColor:
      selectedAttribute == "background_color" && Activitystarted
        ? ValueDropdown
        : "white",
    fontSize:
      selectedAttribute == "fontSize" && Activitystarted
        ? `${ValueDropdown}px`
        : "5px",
    height: "121.5%", // Inherit the height from the parent
    width: "111%",
    borderRadius: "30px",
    marginLeft: "-11px",
    marginTop: "-45px",
  };
  const data = {
    Label: {
      background_color: ["red", "blue", "green"],
      fontSize: ["8", "15", "20"],
    },
    Screen: { background_color: ["red", "blue", "green", "yellow"] },
  };

  function handleHtmlCode() {
    const data = getHtmlCode.current.outerHTML;
    setHtmlCode(data);
  }

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  return (
    <div class="container" style={{ overflow: "hidden" }}>
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="Newbody">
            <div className="Newheader">
              {Finalscreen == true ? (
                <div className="design-vew22" ref={getHtmlCode}>
                  {!ClickedNext && !dragging ? (
                    <div style={divStyle}>
                      {Activitystarted ? (
                        <p
                          style={{
                            fontSize: SelectedFontsize,
                            padding: "20px",
                          }}
                        >
                          Make Me Bigger........
                        </p>
                      ) : null}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {/* <div class="nav-controls2 d-flex justify-content-between align-items-center"> */}
              <button
                className="button-12 mt-2"
                style={{ textAlign: "center" }}
                onClick={() =>
                  Activitystarted
                    ? (setClickedNext(false), setActivitystarted(false))
                    : setActivitystarted(true)
                }
              >
                {Activitystarted ? "RESET" : "RUN"}
              </button>
              {/* </div> */}
              {/* <button className="button-12" style={{ textAlign: "center" }} onClick={Goout}>Finish</button> */}
            </div>
          </div>
        </div>
        <div class="lg:col-span-9 col-span-12 mt-5">
          <i class="fa-duotone fa-up-down-left-right fa-spin fa-spin-reverse"></i>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="NavbarApp">&emsp;Instructions</div>
          </div>
          <div>
            <p>
              <strong>Goal:</strong> Make the screen green.{" "}
            </p>
            <p>
              <strong>How:</strong> Drag in a <code>setProperty()</code> block.
              Use the dropdowns to set the screen's{" "}
              <code>"background-color"</code> to <code>"green"</code>. Then hit
              "Run".
            </p>
          </div>

          <div className=" row ">
            <div className="col-6 ">
              <div class="box">
                <div class="box-head">
                  &emsp;Toolbox
                  <a class="yellow-btn">
                    <i class="fa-solid fa-minus"></i>
                  </a>
                  <iconify-icon
                    icon="icon-park-twotone:right-c"
                    width="20"
                    class="iconify-icon-right"
                  ></iconify-icon>
                  <iconify-icon
                    icon="ant-design:setting-outlined"
                    width="20"
                    class="iconify-icon-left"
                  ></iconify-icon>
                  {}
                </div>
              </div>

              <div class="box-content p-3">
                <div class="controlhead">UI Controls</div>
                <div>&emsp;</div>
                <div onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                  <div
                    class={selectedpopup ? null : "pin"}
                    draggable="true"
                    id="1"
                  >
                    <div
                      class="divtochange flex"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      setProperty{!divtochangeContent && "(id,property,value)"}
                      {divtochangeContent && (
                        <div>
                          (
                          <select
                            class="selectinpin "
                            onChange={handleScreenChange}
                            value={SelectedScreen}
                          >
                            <option value=""></option>
                            {Object.keys(data).map((Screen, index) => (
                              <option value={Screen}>{Screen}</option>
                            ))}
                          </select>
                          <select
                            class="selectinpin"
                            onChange={handleAttributeChange}
                            value={selectedAttribute}
                          >
                            <option value="">Select an Option</option>
                            {data &&
                              data[SelectedScreen] &&
                              Object.keys(data[SelectedScreen]).map(
                                (Screen, index) => (
                                  <option value={Screen}>{Screen}</option>
                                )
                              )}
                          </select>
                          <select
                            class="selectinpin"
                            onChange={(e) => setValueDropdown(e.target.value)}
                            value={ValueDropdown}
                          >
                            <option value="">Select</option>
                            {data[SelectedScreen] &&
                              data[SelectedScreen][selectedAttribute] &&
                              data[SelectedScreen][selectedAttribute].map(
                                (Screen, index) => (
                                  <option value={Screen}>{Screen}</option>
                                )
                              )}
                          </select>
                          )
                        </div>
                      )}
                    </div>
                  </div>
                  {}
                </div>
              </div>
            </div>
            <div className="col-9">
              <div class="box">
                <div class="box-head">
                  &emsp;Workspace
                  <a
                    class="btn "
                    onClick={() => {
                      popupvalue();
                      handleHtmlCode();
                    }}
                  >
                    <button>Show Code</button>
                  </a>{" "}
                  <a
                    class="btn me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#show-code-popup"
                  >
                    <button>Version History</button>
                  </a>
                  {selectedpopup && (
                    <div className="modal">
                      <div className="overlay">
                        <div className="modal-content">
                          {htmlCode ? htmlCode : null}
                          <button
                            className="btn"
                            style={{ marginTop: "30px" }}
                            onClick={popupvalue}
                          >
                            close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  class="box-content p-3"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div class="plug-board work-space" id="drop-zone"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appone;
