/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  counter1,
  counter2,
  counter3,
  counter4,
} from "../../constant/images";
import { StudentCloudFrontDistributionID } from "../../api/aws-api";

import CountUp from "react-countup";


const HomePageUser = (props) => {
  const total_hours = props.batchProgress ? props.batchProgress.total : 0
  const completed_hours = props.batchProgress ? props.batchProgress.completed : 0
  
  return (
    <div className="section-padding bg-[url('../images/all-img/insbg.png')] bg-contain bg-no-repeat">
      <div className="container">
        <div className="grid grid-cols-12 xl:gap-0 gap-[30px]">
          <div className="lg:col-span-3 col-span-12">
            <div className="bg-white shadow-box7 rounded-md max-w-[90%] sm:max-w-[350px] lg:sticky lg:top-10 mx-auto rounded-t-md">
              <div className="h-full ml-4 mr-4 rounded-md  rounded-t-md pt-4">
                <img
                  src={props.data ? props.data.user_image ? StudentCloudFrontDistributionID +"/studentimages/" + props.data.user_image : StudentCloudFrontDistributionID + "/studentimages/dummyUser.webp" : StudentCloudFrontDistributionID + "/studentimages/dummyUser.webp" }
                  alt=""
                  className="w-full h-full block object-cover  rounded-total-md"
                />
              </div>
              {/*props.data ? props.data.user_image ? "":<span 
                    className="h-8 w-8 rounded bg-red-paste text-primary flex flex-col justify-center items-center text-center transition
                              hover:bg-primary hover:text-white ml-4 mt-2" onClick={() => fileInputRef.current.click()}
                  >
                    <iconify-icon icon="mdi:account-upload"></iconify-icon>
            </span> :"" */}
                  
              <div className="px-8 pb-8">
                <div style={{ display: 'flex', alignItems: 'center' }} className="mb-8 mt-4">
                <h5 className=" text-2xl font-bold text-black mr-4">
                  {props.data ? props.data.user_name : "client name"}
                </h5>

                 
                </div>
                {props.data ? props.data.course? props.data.course[0].batch_mentor_name ?
                <div className="mb-4 mini-title" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="h-8 w-8 rounded bg-green-paste text-secondary flex flex-col justify-center items-center text-lg
                                pr-10">
                    <iconify-icon icon="mdi:teacher" className="pl-10"></iconify-icon> </div>
                    &nbsp;&nbsp;
                    { props.data.course[0].batch_mentor_name}
                  </div>
                  <Link to="/codomo/contactus" 
                    state={{ course: props.data ? props.data.course ? props.data.course[0].course_id ? props.data.course[0].course_id : "" : "" : "",
                            mentor : props.data ? props.data.course ? props.data.course[0].batch_mentor_id  : "" : "" }}
                    className="h-10 w-10 rounded bg-red-paste text-primary flex flex-col justify-center items-center text-2xl transition
                              hover:bg-primary hover:text-white pr-10"
                  >
                    <iconify-icon icon="ic:baseline-chat"></iconify-icon>
                  </Link>
                </div> : "":"":"" }

                <ul className=" space-y-[19px]">
                  <li className=" flex items-center space-x-3">
                    <div className="flex-none">
                      <span className="h-8 w-8 rounded bg-green-paste text-secondary flex flex-col justify-center items-center text-lg transition  hover:bg-secondary hover:text-white">
                        <iconify-icon icon="heroicons:envelope"></iconify-icon>
                      </span>
                    </div>
                    <span className="text-md flex-1 ">{props.data ? props.data.user_email : "client email"}</span>
                  </li>
                  <li className=" flex items-center space-x-3">
                    <div className="flex-none">
                      <span className="h-8 w-8 rounded bg-green-paste text-secondary flex flex-col justify-center items-center text-lg transition  hover:bg-secondary hover:text-white">
                        <iconify-icon icon="heroicons:phone"></iconify-icon>
                      </span>
                    </div>
                    <span className="text-md flex-1">{props.data ? props.data.user_mobile : "client mobile"}</span>
                  </li>
                  <li className=" flex items-center space-x-3">
                    <div className="flex-none">
                      <span className="h-8 w-8 rounded bg-green-paste text-secondary flex flex-col justify-center items-center text-lg transition  hover:bg-secondary hover:text-white">
                        <iconify-icon icon="heroicons:map-pin"></iconify-icon>
                      </span>
                    </div>
                    <span className="text-md flex-1">{props.data ? props.data.user_address : "client address"}</span>
                  </li>
                  {props.data ? props.data.parent_name ?
                  <li className=" flex items-center space-x-3">
                    <div className="flex-none">
                      <span className="h-8 w-8 rounded bg-green-paste text-secondary flex flex-col justify-center items-center text-lg transition  hover:bg-secondary hover:text-white">
                        <iconify-icon icon="ri:parent-line"></iconify-icon>
                      </span>
                    </div>
                    <span className="text-md flex-1">{ props.data.parent_name}</span>
                  </li> : "" : ""}
                  {props.data ? props.data.course ? props.data.course[0].batch_id ?
                  <li className=" flex items-center space-x-3">
                    <div className="flex-none">
                      <span className="h-8 w-8 rounded bg-green-paste text-secondary flex flex-col justify-center items-center text-lg transition  hover:bg-secondary hover:text-white">
                        <iconify-icon icon="ic:outline-people"></iconify-icon>
                      </span>
                    </div>
                    <span className="text-md flex-1">{props.data ? props.data.course ? props.data.course[0].batch_id : "no batch selected": "batch number"}</span>
                  </li> : "" : "": ""}
                </ul>
                <hr className="mt-4"></hr>

                {props.data ? <div className=" flex items-center space-x-3 mt-4">
                <div className="flex-none">
                <Link to= "/codomo/editprofile" state = {{data : props.data}}
                    className="h-8 w-8 rounded bg-red-paste text-primary flex flex-col justify-center items-center  transition
                              hover:bg-primary hover:text-white " 
                  >
                    <iconify-icon icon="material-symbols:edit-sharp" ></iconify-icon></Link>
                  </div> <span className="text-md flex-1">Edit Your Profile</span>

                  </div> : ""}
              </div>
            </div>
          </div>
          <div className="lg:col-span-9 col-span-12">
            <div className=" grid xl:grid-cols-3 sm:grid-cols-3 grid-cols-1 gap-[30px] mt-5">
              <div className="bg-white shadow-box7 text-center pt-[64px] pb-8 px-[50px]  rounded-[8px] relative my-4">
                <img
                  src={counter1}
                  alt=""
                  className=" absolute left-1/2 -translate-x-1/2 -top-10"
                />

                <h4 className=" text-[44px] leading-[66px] text-black font-bold mb-1 ">
                  <span className="counter"><CountUp end={completed_hours}/></span>
                </h4>
                <p>Completed Hours</p>
              </div>

              <div className="bg-white shadow-box7 text-center pt-[64px] pb-8 px-[50px]  rounded-[8px] relative my-4">
                <img
                  src={counter2}
                  alt=""
                  className=" absolute left-1/2 -translate-x-1/2 -top-10"
                />
                <h4 className=" text-[44px] leading-[66px] text-black font-bold mb-1 ">
                  <span className="counter"><CountUp end={total_hours - completed_hours}/></span>
                </h4>
                <p>Remaining Hours</p>
              </div>

              <div className="bg-white shadow-box7 text-center pt-[64px] pb-8 px-[50px]  rounded-[8px] relative my-4">
                <img
                  src={counter3}
                  alt=""
                  className=" absolute left-1/2 -translate-x-1/2 -top-10"
                />
                <h4 className=" text-[44px] leading-[66px] text-black font-bold mb-1 ">
                  <span className="counter"><CountUp end={(completed_hours/total_hours )* 100 ? (completed_hours/total_hours )* 100 : 0 }/></span>%
                </h4>
                <p>Progress</p>
              </div>
            </div>
            <div className="slideshow-container">
              <div className="carousel">
              {props.notification.length !== 0?
                props.notification.map((item,index)=>( 
                  <blockquote className="bg-red-paste p-4 rounded-md bg-cover bg-no-repeat bg-center my-4 text-primary" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <iconify-icon icon="basil:notification-on-outline" style={{ fontSize: '2em', marginRight: '10px' }} />
                      <div className="text-l font-semibold">{item}</div>
                    </div>
                    <iconify-icon icon="material-symbols:arrow-circle-right-outline" style={{ fontSize: '2em', transition: 'font-size 0.2s ease-in-out' }} onMouseOver={(e) => { e.target.style.fontSize = '2.5em' }} onMouseOut={(e) => { e.target.style.fontSize = '2em' }} /> 
                  </blockquote> 
                ))

              : 
              <div>
              <blockquote className="bg-green-paste p-4 rounded-md bg-cover bg-no-repeat bg-center my-4 text-secondary" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <iconify-icon icon="basil:notification-on-outline" style={{ fontSize: '2em', marginRight: '10px' }} />
                  <div className="text-l font-semibold">New AssignMent Published</div>
                </div>
                <iconify-icon icon="material-symbols:arrow-circle-right-outline" style={{ fontSize: '2em', transition: 'font-size 0.2s ease-in-out' }} onMouseOver={(e) => { e.target.style.fontSize = '2.5em' }} onMouseOut={(e) => { e.target.style.fontSize = '2em' }} /> 
              </blockquote>
              <blockquote className="bg-red-paste p-4 rounded-md bg-cover bg-no-repeat bg-center my-4 text-primary" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <iconify-icon icon="basil:notification-on-outline" style={{ fontSize: '2em', marginRight: '10px' }} />
                  <div className="text-l font-semibold">New Course Have been released in the Course Menu</div>
                </div>
                <iconify-icon icon="material-symbols:arrow-circle-right-outline" style={{ fontSize: '2em', transition: 'font-size 0.2s ease-in-out' }} onMouseOver={(e) => { e.target.style.fontSize = '2.5em' }} onMouseOut={(e) => { e.target.style.fontSize = '2em' }} />              </blockquote>
              <blockquote className="bg-[#ECECEC] p-4 rounded-md bg-cover bg-no-repeat bg-center my-4 text-[#827878]" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <iconify-icon icon="basil:notification-on-outline" style={{ fontSize: '2em', marginRight: '10px' }} />
                  <div className="text-l font-semibold">User Verification is Pending</div>
                </div>
                <iconify-icon icon="material-symbols:arrow-circle-right-outline" style={{ fontSize: '2em', transition: 'font-size 0.2s ease-in-out' }} onMouseOver={(e) => { e.target.style.fontSize = '2.5em' }} onMouseOut={(e) => { e.target.style.fontSize = '2em' }} /> 
                </blockquote> </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default HomePageUser;
