import React, { useState,useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom"
import Header from "../Header";
import { Table } from "antd";
import { FetchCourseDetailsForAdmin,FetchAllCourses} from "../../api/aws-api";
import { ConstructorEventPost,ConstructorEventGet } from "../../Event/event";
import { ClassicSpinner,MagicSpinner } from "react-spinners-kit";
import CourseDetails from "./CourseDetails";

const CourseManagement = () => {
    const [loading,setLoading] = useState(false)
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
      fetchdata()
      },[])


    const fetchdata = async() => {
        setLoading(true)
        const responsedata = await ConstructorEventGet(FetchAllCourses)
        setData(responsedata)
        setLoading(false)
    }

    const columns = [
        {
          title: "course name",
          dataIndex: "course_name",
          sorter: (a, b) => a.course_name.length - b.course_name.length
          
        },
        {
          title: "Course Category",
          dataIndex: "course_catogory",
        },
        {
          title: "course duration",
          dataIndex: "duration",
        },
        {
            title: "last Updated",
            dataIndex: "last_updated",
        },
        {
          title: "More Actions",
          render: (record) => <div >
            <Link to="/codomo/admin/coursemanagement" state={{"data" : record}}><iconify-icon icon="ri:edit-fill" style={{ color:"red" ,fontSize: '1.8em', marginLeft: '10px' }} ></iconify-icon></Link>
            <Link to="/codomo/admin/activitymanagement" state={{"data" : record}}><iconify-icon icon="icon-park-outline:activity-source" style={{ color:"blue" ,fontSize: '1.8em', marginLeft: '20px' }} ></iconify-icon></Link>
            <Link to="/codomo/admin/downloadsmanagement" state={{"data" : record}}><iconify-icon icon="material-symbols:download" style={{color:"green", fontSize: '1.8em', marginLeft: '20px' }}></iconify-icon></Link>
            <Link to="/codomo/admin/mappingmanagement" state={{"data" : record}}><iconify-icon icon="ic:outline-map" style={{color:"#5B235C", fontSize: '1.8em', marginLeft: '20px' }}></iconify-icon></Link>
            </div>
        }
        
      ];

    return (
        <>
          <Header/>
          {loading ? 
            <div className="spinner" >
              <div className="spinner-wrapper">
                <ClassicSpinner size={50} color ="black" loading={loading} />
              </div>
            </div> 
          :
            <div className="nav-tab-wrapper tabs  section-padding">
              <div className="container">
                <div className="mini-title">Course Management
                  <div className="small-btn ml-4" onClick={()=>navigate('/codomo/admin/createnewcourse')}>
                    New Course {" "}
                    <iconify-icon icon="pajamas:doc-new" ></iconify-icon>
                  </div> 
                </div>
                <div className="col-span-12">
                  <div className="bg-white shadow-box7 p-8 rounded-md">
                    {/*<div className="from-control mb-4">course :{filteredData.length}</div>*/}
                    <Table    
                        className=""      
                        columns={columns}
                        dataSource={data}      
                        pagination={data.length > 9 ?true :false}
                        rowKey={(record) => record.key}
                    />
                  </div>
                </div>
                
              </div>
            </div>
          }                             
        </>
    )

}
export default CourseManagement;