import React, { useState, useRef } from "react";
import "../../assets/css/newacitivity.css";

export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();
  const [selectedOption, setSelectedOption] = useState("");

  const [questionNumber, setQuestionNumber] = useState(0);
  const [Activitystarted, setActivitystarted] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [SelectedScreen, setSelectedScreen] = useState("");
  const [ClickedNext, setClickedNext] = useState(false);
  const [dragging, setDragging] = useState(true);
  const [divtochangeContent, setDivtochangeContent] = useState([]);
  const [ValueDropdown, setValueDropdown] = useState("");
  const [screen, setScreen] = useState("");
  const [selectedpopup, setSelectedpopup] = useState(false);

  // Handle dropdown change event
  const handleColorChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedColor(event.target.value);
    }
  };
  const handleAttributeChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedAttribute(event.target.value);
    }
  };
  const handleScreenChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedScreen(event.target.value);
    }
  };
  const handleDragStart = (e) => {
    if (Activitystarted === true) {
      e.dataTransfer.setData("text/plain", e.target.id);
      setDragging(true);
    }
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData("text/plain");
    const droppedItem = document.getElementById(droppedItemId);
    // Update the content of divtochangeContent here based on the drop event
    if (droppedItem instanceof HTMLElement) {
      // Check if droppedItem is a valid DOM node
      setDivtochangeContent([...divtochangeContent, droppedItemId]);
      e.target.appendChild(droppedItem);
      setDragging(false);
    } else {
      console.error(`Element with ID ${droppedItemId} not found or not valid.`);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const colorMap = {
    Red: "red",
    Green: "green",
    Blue: "blue",
    Yellow: "yellow",
  };
  const screenMap = {
    one: "../images/NewActivityImages/mobile3.jpg",
    two: "../images/NewActivityImages/mobile4.jpg",
    three: "../images/NewActivityImages/mobile5.jpg",
    four: "../images/NewActivityImages/Project.jpg",
  };
  const labelMap = {
    "16px": "red",
    "16px": "green",
    "20px": "blue",
    "24px": "yellow",
    "16px": "yellow",
  };

  const divStyle = {
    backgroundColor:
      selectedAttribute == "background_color" ? ValueDropdown : "white",
    fontSize: selectedAttribute == "fontSize" ? `${ValueDropdown}px` : "5px",
    height: "99.99%", // Inherit the height from the parent
    width: "inherit",
    borderRadius: "30px",
  };

  function handleHtmlCode() {
    const data = getHtmlCode.current.outerHTML;
    console.log("The extracted HTML code is----", data);
    setHtmlCode(data);
  }

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  const Dropdownselect = (event) => {
    var selectedvalue = event.target.value;
    setSelectedOption(selectedvalue);
    console.log(selectedvalue);
  };
  return (
    <div class="container" style={{ overflow: "hidden" }}>
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="Newbody">
            <div className="Newheader">
              <div className="design-vew7" ref={getHtmlCode}>
                {(screen === "Left" &&
                  selectedOption === "click" &&
                  Activitystarted) ||
                (Activitystarted && screen === "Left") ? (
                  <div>
                    <div
                      className="design-vew7box"
                      style={{ paddingTop: "65px" }}
                    >
                      You Went to Left Screen
                    </div>
                    <button
                      className="buttonsessionActivity9"
                      style={{ marginLeft: "70px" }}
                      value="Right"
                      onClick={(e) => setScreen(e.target.value)}
                    >
                      Right
                    </button>
                  </div>
                ) : (screen === "Right" &&
                    selectedOption === "click" &&
                    Activitystarted) ||
                  (Activitystarted && screen === "Right") ? (
                  <div>
                    <div
                      className="design-vew7box"
                      style={{ paddingTop: "65px" }}
                    >
                      You Went to Right Screen
                    </div>
                    <button
                      className="buttonsessionActivity9"
                      style={{ marginLeft: "70px" }}
                      value="Left"
                      onClick={(e) => setScreen(e.target.value)}
                    >
                      Left
                    </button>
                  </div>
                ) : (
                  <div>
                    <div className="design-vew7box">
                      Choose Your Adventure! Do you Want to go LEFT or RIGHT
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="buttonsessionActivity9"
                        value="Left"
                        onClick={(e) => setScreen(e.target.value)}
                      >
                        Left
                      </button>
                      <button
                        className="buttonsessionActivity9"
                        value="Right"
                        onClick={(e) => setScreen(e.target.value)}
                      >
                        Right
                      </button>
                    </div>
                  </div>
                )}
                {/* {screen == "Left" ? (
                  <>
                    <div className="design-vew7box">
                      Choose Your Adventure! Do you Want to go LEFT or RIGHT
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <button className="buttonsessionActivity9">Left</button>
                      <button
                        className="buttonsessionActivity9"
                        onClick={(e) => setScreen(e.target.value)}
                      >
                        Right
                      </button>
                     
                    </div>
                  </>
                ) : (
                  <h2 className="design-vew7box">You Went Right</h2>
                )} */}

                {!ClickedNext ? <div className="mt-14"></div> : ""}
              </div>
              {/* <div class="nav-controls2 d-flex justify-content-between align-items-center"> */}
              <button
                className="button-12 mt-2"
                style={{ textAlign: "center" }}
                onClick={() =>
                  Activitystarted
                    ? (setClickedNext(false), setActivitystarted(false))
                    : setActivitystarted(true)
                }
              >
                {Activitystarted ? "RESET" : "RUN"}
              </button>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div class="lg:col-span-9 col-span-12 mt-5">
          <i class="fa-duotone fa-up-down-left-right fa-spin fa-spin-reverse"></i>
          <div className="NavbarApp">&emsp;Instructions</div>
          <div>
            <p>
              <strong>Goal:</strong> The screen switches to "rightScreen" when
              the user clicks the "rightButton".{" "}
            </p>
            <p>
              <strong>How:</strong>In Code Mode add a new onEvent() to your
              program for your "Right!" button. Add a setScreen() block inside
              of it that switches screens to "rightScreen".
            </p>
          </div>

          <div className=" row ">
            <div className="col-6 ">
              <div class="box">
                <div class="box-head">
                  &emsp;Toolbox
                  <a class="yellow-btn">
                    <i class="fa-solid fa-minus"></i>
                  </a>
                  <iconify-icon
                    icon="icon-park-twotone:right-c"
                    width="20"
                    class="iconify-icon-right"
                  ></iconify-icon>
                  <iconify-icon
                    icon="ant-design:setting-outlined"
                    width="20"
                    class="iconify-icon-left"
                  ></iconify-icon>
                  {}
                </div>
              </div>

              <div class="box-content p-3">
                <div class="controlhead">UI Controls</div>
                <div>&emsp;</div>
                <div onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                  <div
                    class={selectedpopup ? null : "pin"}
                    draggable="true"
                    id="1"
                  >
                    <div
                      class="divtochange flex"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      setProperty
                      {!divtochangeContent.includes("1") &&
                        "(id,property,value)"}
                      {divtochangeContent.includes("1") && (
                        <div>
                          <select
                            className="dropdown"
                            onChange={(e) => setScreen(e.target.value)}
                          >
                            <option value="Left">Left Screen</option>
                            <option value="Right">Right Screen</option>
                          </select>
                        </div>
                      )}
                    </div>
                  </div>

                  <div class=" draggable" draggable="true" id="2">
                    <div class="box-content p-3">
                      <div
                        class={selectedpopup ? null : "plug-board work-space"}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                      >
                        <div class="plug-board-top">
                          OnEvent
                          <select
                            className="dropdown"
                            onChange={Dropdownselect}
                          >
                            <option value="">Select an option</option>
                            <option value="click">Click</option>

                            <div class="function">Function</div>
                          </select>
                        </div>

                        <div class="plug">
                          <div
                            class="plug-blue"
                            ondrop="drop(event)"
                            ondragover="allowDrop(event)"
                          ></div>
                        </div>

                        <div class="plug-board-bottom">
                          <div class="bottom-blue"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9">
              <div class="box">
                <div class="box-head">
                  &emsp;Workspace
                  <a
                    class="btn "
                    onClick={() => {
                      popupvalue();
                      handleHtmlCode();
                    }}
                  >
                    <button>Show Code</button>
                  </a>{" "}
                  <a
                    class="btn me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#show-code-popup"
                  >
                    <button>Version History</button>
                  </a>
                  {selectedpopup && (
                    <div className="modal">
                      <div className="overlay">
                        <div className="modal-content">
                          {htmlCode ? htmlCode : null}
                          <button
                            className="btn"
                            style={{ marginTop: "30px" }}
                            onClick={popupvalue}
                          >
                            close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  class="box-content p-3"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div class="plug-board work-space" id="drop-zone"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appone;
