import React, { useState,useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom"
import Header from "../Header";
import { Table } from "antd";
import {UpdateCourseDetails} from "../../api/aws-api";
import { ConstructorEventPost } from "../../Event/event";
import { ClassicSpinner } from "react-spinners-kit";
import { notifyError, notifySuccess } from "../../Common/CommonFunction";

const CourseDownloadsManagement = () => {
    const [loading,setLoading] = useState(false)
    const [ActivityEditMenu,setActivityEditMenu] = useState(false)
    const [SaveToCloud,setSaveToCloud] = useState(false)
    const [data,setData] = useState()

    const [DisplayData,setDisplayData] = useState([])
    const [FileName,setFileName] = useState("")
    const [FileID,setFileID] = useState("")
    const [FileKey,setFileKey] = useState("")
    
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(()=>{
        const {data} = location.state ? location.state : []
        if (data){setData(data);setDisplayData(data["session"] ? data["session"] : [])}
      },[])
   
    const EraseDataFromState = () => {
      setFileName("")
      setFileID("")
      setFileKey("")
    }
    const setDataToEditTab = (data) => {
        setFileName(data.SessionName)
        setFileID(data.SessionID)
        setFileKey(data.FileKey)
      }
    const columns = [
        {
          title: "File Name",
          dataIndex: "SessionName",
          sorter: (a, b) => a.SessionName.length - b.SessionName.length,
          render: (text, record) => <Link onClick={()=> (setDataToEditTab(record),setActivityEditMenu(true))}>{text}</Link>
        },
        {
          title: "File ID",
          dataIndex: "SessionID",
        },
        {
            title: "File Key",
            dataIndex: "FileKey",
        },
        {
          title: " ",
          dataIndex: "SessionID",
          render: (text) => <div >
            <iconify-icon icon="line-md:remove" style={{ color:"red" ,fontSize: '1.8em', marginLeft: '20px' }} onClick={()=>RemoveItemFromdata(text)}/>
            </div>
        }
        
      ];
       
      const UpdateDataInState = () => {
        const temp = {
          "SessionName" : FileName,
          "SessionID" : FileID,
          "FileKey" : FileKey
        }
        const Repetation = DisplayData.filter((item)=> item.SessionID !== FileID)
        setDisplayData([...Repetation,temp])
        setActivityEditMenu(false)
        setSaveToCloud(true)
      }
  
      const RemoveItemFromdata = (sessdionIdOfFile) => {
        const Repetation = DisplayData.filter((item)=> item.SessionID !== sessdionIdOfFile)
        setDisplayData(Repetation)
        setSaveToCloud(true)
      }
         
       
      const UpdateUserDataToCloud = async() => {
        setLoading(true)
        let temp = data
        temp["session"] = DisplayData
        const responsedata = await ConstructorEventPost(UpdateCourseDetails,temp)
        if (responsedata === "course_updated"){notifySuccess("Activity Updated");navigate("/codomo/admin/coursemanagement")}
        else {notifyError("Something Gone Wrong")}
        setLoading(false)
      }


    const temp = {
        "description" : "",
        "description_head" : "",
        "Lectures" : 4,
        "module_name" : "",
        "TotalDurationInMinutes" : 12,
        "resource" : []
    }
       
    return (
        <>
          <Header/>
          {loading ? 
          <div className="spinner" >
            <div className="spinner-wrapper">
              <ClassicSpinner size={50} color ="black" loading={loading} />
              </div></div> :
                <div className="nav-tab-wrapper tabs  section-padding">
                <div className="container">
                  <div className=" grid grid-cols-12 gap-[30px]">
                    <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
                      <div className="mini-title">Downloadable Files Management
                      {SaveToCloud ? <div className="small-btn-saveDataToCloud ml-4" onClick={UpdateUserDataToCloud}>
                        <iconify-icon icon="tabler:cloud-up" style={{fontSize: '1em'}} />
                          Save Changes To Cloud
                        </div> : "" }</div>
                      <div className="small-btn mt-4" onClick={()=>(setActivityEditMenu(true),EraseDataFromState())}>
                        <iconify-icon icon="typcn:plus" style={{fontSize: '1em'}}></iconify-icon> {" "}
                          Add New Downloadable File
                        </div>
                        
                          {ActivityEditMenu ? 
                          <div className="bg-white shadow-box7 p-8 rounded-md mt-8">
                            <div className="input-container mt-8">
                              <input
                                type="text"
                                className="from-control"
                                value={FileName}
                                onChange={(e) => setFileName(e.target.value)}
                                required
                              />
                              <span className={`input-placeholder ${FileName && 'active'}`}>
                              File Name *
                              </span>
                            </div>
                            <div className="input-container mt-8">
                              <input
                                type="email"
                                className=" from-control"
                                value={FileID}
                                onChange={(e) => setFileID(e.target.value)}
                                required
                              />
                              <span className={`input-placeholder ${FileID && 'active'}`}>
                              File ID *
                              </span>
                            </div>
                            <div className="input-container mt-8">
                              <input
                                type="email"
                                className=" from-control"
                                value={FileKey}
                                onChange={(e) => setFileKey(e.target.value)}
                                required
                              />
                              <span className={`input-placeholder ${FileKey && 'active'}`}>
                              File Key *
                              </span>
                            </div>
                            <button className="btn btn-black mt-4 mr-4" onClick={UpdateDataInState}>Save</button>
                          </div> 
                        : ""}
                        
                        
                    </div>
                    <div className="xl:col-span-7 lg:col-span-6 col-span-12">
                      <div className="bg-white shadow-box7 p-8 rounded-md">
                      <div className="from-control mb-4">Total Activity : {DisplayData && DisplayData.length}</div>
                        <Table    
                            className=""      
                            columns={columns}
                            dataSource={DisplayData ? DisplayData: []}      
                            pagination={DisplayData && DisplayData.length > 9 ?true : false}
                            rowKey={(record) => record.key}
                        />
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>}

        </>
    )

}
export default CourseDownloadsManagement;