import React, { useState,useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom"
import Header from "../Header";
import { Table } from "antd";
import { UpdateSingleClassOfBatch,updateBatchStatus} from "../../api/aws-api";
import { ConstructorEventPost } from "../../Event/event";
import { ClassicSpinner,MagicSpinner } from "react-spinners-kit";
import Swal from 'sweetalert2'

const EditBatch = () => {
    const [status,setStatus] = useState("")
    const [classstatus,setClassStatus] = useState("")
    const [loading,setLoading] = useState(false)
    const [meetlink,setMeetLink] = useState()
    const [selectedClass,setSelectedClass] = useState()
    const [editmeetlink,setEditMeetLink] = useState(false)
    const [editbatchstatus,setEditBatchStatus] = useState(false)
    const location = useLocation();
    const [batchdetails,setBatchDetails] = useState()
    const navigate = useNavigate()
    useEffect(()=>{
        const {batch} = location.state ? location.state : []
        setBatchDetails(batch)
      },[])
    const columns = [
        {
          title: "Students",
          dataIndex: "name",
          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Phone",
            dataIndex: "mobile",
        }
      ];
      const columnsClassSchedule = [
        {
          title: "Class Id",
          dataIndex: "scheduleNumber",
          sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Start",
            dataIndex: "startDateTime",
        },
        {
            title: "End",
            dataIndex: "endDateTime",
        },
        {
            title: "Meet Link",
            dataIndex: "meet_link",
        },
        {
          title: "Status",
          dataIndex: "status",
      }
      ];
      
    const savechanges = async() => {
        const params = {
            "mentorId" : batchdetails.mentorId,
            "BatchName" : batchdetails.BatchName,
            "meet_link" : meetlink,
            "scheduleNumber": selectedClass.scheduleNumber,
            "status" : classstatus
          }
        setLoading(true)
        const responsedata = await ConstructorEventPost(UpdateSingleClassOfBatch,params)
        if (responsedata[0] === "updated"){setLoading(false);setBatchDetails(responsedata[1]);setEditBatchStatus(false);setEditMeetLink(false);setSelectedClass("");setMeetLink("");setClassStatus("")}
        else {setLoading(false)}
    }   
    const updateDatainCloud = async(params) => {
      setLoading(true)
      const responsedata = await ConstructorEventPost(updateBatchStatus,params)
      if (responsedata[0] === "updated"){setLoading(false);setBatchDetails(responsedata[1]);setEditBatchStatus(false);setEditMeetLink(false);setStatus("")}
      else {setLoading(false)}

    }
    const UpdateBatchProgress = (statusupdate) => {
      const params = {
        "mentorId" : batchdetails.mentorId,
        "BatchName" : batchdetails.BatchName,
        "batchProgress" : statusupdate
      }
      Swal.fire({
        title: statusupdate === "Cancelled" ? "Do you wish to cancel this batch?" : statusupdate === "Discontinued" ? "Would you like to Discontinue this batch?" : 
        statusupdate === "InProgress" ? "Would you like to switch the status of this batch to in progress?" : statusupdate === "Completed" ? "Would you like to switch the status of this batch to Completed ?" : "",
        text: statusupdate === "Cancelled" ? "Cancel " + batchdetails.BatchName + " ?" : statusupdate === "Discontinued" ? "Discontinue " + batchdetails.BatchName + " ?" : 
        statusupdate === "InProgress" ? "Started " + batchdetails.BatchName + " ?" : statusupdate === "Completed" ? "Completed" + batchdetails.BatchName + " ?" : "",
        icon: statusupdate === "InProgress" ? "success" :statusupdate === "Completed" ? "success" : "warning",
        iconColor : statusupdate === "InProgress" ?  "#4df766" :statusupdate === "Completed" ?  "#4df766" : "#fa414a",
        confirmButtonText: 'Yes, please',
        confirmButtonColor : "#FF7E84",
        cancelButtonColor : "#30BEAD",
        showCancelButton : true,
        cancelButtonText:"Nope,Changed my mind",
      }).then((result) => {
        if (result.isConfirmed) {
          if (statusupdate === "InProgress"){
            Swal.fire({
              title: 'Enter Meet Link',
              html:
                '<input id="swal-input" type="text" class="swal2-input from-control-1" placeholder="Enter Class Meet Link" >',
              showCancelButton: true,
              confirmButtonText: 'Proceed',
              cancelButtonText: 'Cancel',
              confirmButtonColor : "#FF7E84",
              cancelButtonColor : "#30BEAD",
              preConfirm: () => {
                const input = document.getElementById('swal-input');
                if (input) {
                  params["meet_link"] = input.value
                }
              }
            }).then((confirm) => {if (confirm.isConfirmed) {
                updateDatainCloud(params)
            }})
          }
          else{updateDatainCloud(params)}
        }
      });
    } 

    return (
        <>
            <Header/>
            {loading ? 
    <div className="spinner" >
    <div className="spinner-wrapper">
      <ClassicSpinner size={50} color ="black" loading={loading} />
      </div></div> :
          <div className="nav-tab-wrapper tabs  section-padding">
        <div className="container">
          <div className=" grid grid-cols-12 gap-[30px]">
            <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
              <div className="mini-title">Edit Your Batch</div>
                <div className="bg-white shadow-box7 p-8 rounded-md mt-8">
                    <div className="fflex-none mr-6 mt-4  focus:ring-0 flex items-center">
                      Date : {batchdetails && batchdetails.StartDate} {"   →   "}{batchdetails && batchdetails.EndDate} ({batchdetails && batchdetails.Repeat})
                  </div>
                  <div className="fflex-none mr-6 mt-4  focus:ring-0 flex items-center">
                  Time : {batchdetails&&batchdetails.StartTime} {"   →   "}{batchdetails&&batchdetails.EndTime}
                  </div> 
                  {editbatchstatus ? 
                  <div className="pb-4 mt-4"> Status</div>
                  :
                  <div className="fflex-none mr-6 mt-4  focus:ring-0 flex items-center">
                  Status : {batchdetails&&batchdetails.batchProgress} 
                  </div> }
                  {!editmeetlink && !editbatchstatus? 
                  <div>
                 { batchdetails&&batchdetails.batchProgress === "InProgress" ? <button className="btn btn-primary mt-4 mr-4" onClick={()=>setEditMeetLink(true)}>Edit Class</button>:"" }
                 { batchdetails&&batchdetails.batchProgress === "Scheduled" ? <button className="btn btn-secondary mt-4 mr-4" onClick={()=>setEditBatchStatus(true)}>Change Status</button>:"" }
                 { batchdetails&&batchdetails.batchProgress === "InProgress" ? <button className="btn btn-secondary mt-4 mr-4" onClick={()=>setEditBatchStatus(true)}>Edit Batch Status</button>:"" }
                 </div> 
                 : editmeetlink && !editbatchstatus ? 
                 <div>

                    <select
                        className="from-control focus:ring-0 mt-4 bg-[#ECECEC]"
                        onChange={(e) => {const indclass = e.target.value && batchdetails.ClassSchedule.filter(obj => obj.scheduleNumber === parseInt(e.target.value))[0];setClassStatus(indclass ? indclass["status"] : "");setMeetLink(indclass ? indclass["meet_link"] : "");setSelectedClass(indclass);}}
                        required>
                          <option key="" value="">Select Class</option>
                        {batchdetails&&batchdetails.ClassSchedule.map((item1,index)=> (
                            <option key={item1.scheduleNumber} value={item1.scheduleNumber}>{item1.startDateTime.split("T")[0]}</option>
                        ))}
                    </select>
                    <input
                      type="text"
                      className="from-control mt-4"
                      value={meetlink}
                      placeholder="Meet Link"
                      onChange={(e) => setMeetLink(e.target.value)}
                      required
                    />
                    <div className="input-container">
                      <select
                          className="from-control p-4 bg-[#ECECEC] text-[#827878] mt-4"
                          value={classstatus}
                          onChange={(e) => setClassStatus(e.target.value)}
                          required
                      >
                          <option key="Scheduled" value="Scheduled">Scheduled</option>
                          <option key="InProgress" value="InProgress">InProgress</option>
                          <option key="Completed" value="Completed">Completed</option> 
                      </select>
                    </div>
                  {selectedClass ? selectedClass.meet_link !== meetlink ? <button className="btn btn-black mt-4 mr-4" onClick={ savechanges} >Save Changes</button>:selectedClass.status !== classstatus ? <button className="btn btn-black mt-4 mr-4" onClick={ savechanges} >Save Changes</button>:"":""}
                  </div>:
                  !editmeetlink && editbatchstatus ? 
                  <div>
                    <div className="input-container">
                      <select
                          className="from-control p-4 bg-[#ECECEC] text-[#827878] "
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                          required
                      >
                          {batchdetails.batchProgress === "Scheduled" ? <option key="" value="">Scheduled</option> : "" }
                          {batchdetails.batchProgress === "Scheduled" ? <option key="InProgress" value="InProgress">InProgress</option> : "" }
                          {batchdetails.batchProgress === "Scheduled" ? <option key="Cancelled" value="Cancelled">Cancelled</option> : "" }

                          {batchdetails.batchProgress === "InProgress" ? <option key="" value="">InProgress</option> : "" }
                          {batchdetails.batchProgress === "InProgress" ? <option key="Completed" value="Completed">Completed</option> : "" }
                          {batchdetails.batchProgress === "InProgress" ? <option key="Discontinued" value="Discontinued">Discontinued</option> : "" }
                          
                          {/*<option key="InProgress" value="InProgress">InProgress</option>
                          <option key="Completed" value="Completed">Completed</option>
                          <option key="Cancelled" value="Cancelled">Cancelled</option>
                          <option key="Discontinued" value="Discontinued">Discontinued</option>*/}
                      </select>
                      </div>
                      {status ? <button className="btn btn-black mt-4 mr-4" onClick={() => UpdateBatchProgress(status)} >Save Changes</button> : ""}
                  </div >
                  :
                  ""
                  }

                </div>
                
            </div>
            <div className="xl:col-span-7 lg:col-span-6 col-span-12">
              <div className="bg-white shadow-box7 p-8 rounded-md">
                <Table    
                    className=""      
                    columns={columns}
                    dataSource={batchdetails&&batchdetails.StudentsEndrolled}      
                    pagination={batchdetails&&batchdetails.StudentsEndrolled.length > 9 ?true :false}
                    rowKey={(record) => record.key}
                />
              </div>
            </div>
            
          </div>
          <div className="bg-white shadow-box7 p-8 rounded-md mt-4">
                <Table    
                    className=""      
                    columns={columnsClassSchedule}
                    dataSource={batchdetails&&batchdetails.ClassSchedule}      
                    pagination={batchdetails&&batchdetails.ClassSchedule.length > 9 ?true :false}
                    rowKey={(record) => record.scheduleNumber}
                />
              </div>
        </div>
      </div>}

        </>
    )

}
export default EditBatch;