import React ,{useEffect, useState}from "react";
import Header from "../Header";
import { Table,Input } from "antd";
import { useLocation ,useNavigate} from "react-router-dom"
import "../../assets/css/antdstyle.css";
import { ClassicSpinner } from "react-spinners-kit";
import { ConstructorEventGet,ConstructorEventPost } from "../../Event/event";
import { FetchStudentDetails,AddNewBatch } from "../../api/aws-api";



const AddStudent = () => {
    const [loading,setLoading] = useState(true)
    const [submitloading,setSubmitloading] = useState(false)
    const [addedStudents,setAddedStudents] = useState([])
    const [batchDetails,setBatchDetails] = useState([])
    const [enrolled,setEnrolled] = useState([])
    const [filteredData, setFilteredData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const location = useLocation();
    const navigate = useNavigate()

    useEffect(()=>{
        const {data} = location.state ? location.state : []
        console.log(data,"data")
        setBatchDetails(data)
        fetchdata() 
    },[])

    const fetchdata = async() => {
        const responsedata = await ConstructorEventGet(FetchStudentDetails)
        setEnrolled(responsedata);
        setFilteredData(responsedata)
        setLoading(false)
    }
    
    const columns = [
        {
          title: "Students",
          dataIndex: "name",
          sorter: (a, b) => a.name.length - b.name.length,
        }
      ];      

    const handleSearch = (e) => {
        const searchInput = e.target.value.toLowerCase();
        setSearchValue(searchInput);
        console.log(enrolled)
        // Filter the data source based on search input value
        const filtered = enrolled.filter(record =>(record.name.toLowerCase().includes(searchInput)||record.email.toLowerCase().includes(searchInput)||record.mobile.toLowerCase().includes(searchInput)));
        setFilteredData(filtered);
      };
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [enrolledRowKeys, setEnrolledRowKeys] = useState([]);

    const onEnrolledChange = (newEnrolledRowKeys) => {setEnrolledRowKeys(newEnrolledRowKeys);};
    const onSelectChange = (newSelectedRowKeys) => {setSelectedRowKeys(newSelectedRowKeys);};
    const enrolledrowSelection = {selectedRowKeys: enrolledRowKeys,onChange: onEnrolledChange};
    const selectedrowSelection = {selectedRowKeys: selectedRowKeys,onChange: onSelectChange};

    const RemoveStudent = () => { 
        const filteredStudents = addedStudents.filter(item => !selectedRowKeys.includes(item.key));
        setAddedStudents(filteredStudents);
        setSelectedRowKeys([]);
    }

    const handlesubmit = async () => {
        setSubmitloading(true)
        let params = batchDetails
        params["StudentsEndrolled"] = addedStudents
        console.log(params,"params")
        const responsedata = await ConstructorEventPost(AddNewBatch,params)
        if(responsedata === "NewBatchAdded") {navigate("/codomo/mentor/activities")}
        console.log(responsedata,"response")
        setSubmitloading(false)
    }
    
    const AddStudent = () => { 
        const foundObjects = [];
        enrolledRowKeys.forEach(key => {
        const foundObject = enrolled.find(item => item.key === key);
        const duplicate = addedStudents.find(item => item.key === key);
        if (foundObject && !duplicate) {
            foundObjects.push(foundObject);
        }
        });
        setAddedStudents([...new Set([...addedStudents, ...foundObjects])])
        setEnrolledRowKeys([])
    }
    return(
        <>
            <Header/>
            {submitloading ?
            <div className="spinner" >
                <div className="spinner-wrapper">
                    <ClassicSpinner size={50} color ="black" loading={submitloading} />
                </div>
            </div>
            :
            <div className="nav-tab-wrapper tabs  section-padding">
                <div className="container">
                    <div className=" grid grid-cols-12 gap-[30px]">
                        <div className="xl:col-span-5 lg:col-span-5 col-span-5 max-h-100">
                            <div className="bg-white shadow-box7 p-8 rounded-md">
                            <Input
                                placeholder="Search by Name,Phone or Email"
                                className="from-control"
                                value={searchValue}
                                onChange={handleSearch}
                                style={{ marginBottom: 16 }}
                                
                            />
                            <Table    
                                rowSelection={enrolledrowSelection}
                                className=""      
                                columns={columns}
                                dataSource={filteredData.filter(search => !addedStudents.find(item => item.key === search.key))}      
                                pagination={filteredData.filter(search => !addedStudents.find(item => item.key === search.key)).length > 9 ?true :false}
                                rowKey={(record) => record.key}
                                loading={{ indicator: <div><ClassicSpinner size={50} color ="black" loading={loading} /> </div>, spinning: loading}} 
                            />
                            </div>
                        </div>
                        <div className="xl:col-span-2 lg:col-span-2 col-span-2 max-h-100">
                            <div className="bg-white shadow-box7 p-4 rounded-md">
                            <div className="grid gap-4 mt-4">
                                <button className={enrolledRowKeys.length > 0 ? "btn btn-primary mt-4" : "btn btn-disabled mt-4"} disabled={enrolledRowKeys.length > 0 ? false : true} onClick={AddStudent}>Add</button>
                                <button className={selectedRowKeys.length > 0 ? "btn btn-secondary mt-4": "btn btn-disabled mt-4"} disabled={selectedRowKeys.length > 0 ? false : true} onClick={RemoveStudent} >Remove</button>
                                <button className={addedStudents.length > 0 ? "btn btn-black mt-4": "btn btn-disabled mt-4"} disabled={addedStudents.length > 0 ? false : true} onClick={handlesubmit}>Submit</button>
                                </div>


                            </div>
                        </div>
                        <div className="xl:col-span-5 lg:col-span-5 col-span-5 max-h-100">
                            <div className="bg-white shadow-box7 p-8 rounded-md">
                                <div className="from-control mb-4">Batch Count :{addedStudents.length}</div>
                            <Table    
                                rowSelection={selectedrowSelection}
                                className=""      
                                columns={columns}
                                dataSource={addedStudents}      
                                pagination={addedStudents.length > 9 ?true :false}
                                rowKey={(record) => record.key}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )

}

export default AddStudent;