import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/newacitivity.css";
import { SaveHtmlStructure } from "../../api/aws-api";

import { FetchHomePageDetails } from "../../api/aws-api";
import { ConstructorEventGet } from "../../Event/event";

export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();

  const [activitystarted, setActivitystarted] = useState(false);
  const [SelectedScreen, setSelectedScreen] = useState("");
  const [ClickedNext, setClickedNext] = useState(false);
  const [dragging, setDragging] = useState(true);
  const [divtochangeContent, setDivtochangeContent] = useState([]);
  const [screen, setScreen] = useState("Left");
  const [selectedAudio, setSelectedAudio] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [isMobileClicked, setMobileClicked] = useState(false); // New state
  const [selectedAttribute, setSelectedAttribute] = useState(false);
  const [propertyScreen, setPropertyScreen] = useState("");
  const [selectedPropertyColor, setPropertySelectedColor] = useState("");
  const [valuePropertyDropdown, setPropertyValueDropdown] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [ValueDropdown, setValueDropdown] = useState("");
  const [selectedpopup, setSelectedpopup] = useState(false);
  const [userDetails, setUserDetails] = useState([]);

  useEffect(() => {
    const storedData = localStorage.getItem("UserKey");
    if (
      storedData !== "undefined" &&
      storedData !== "null" &&
      storedData !== undefined &&
      storedData !== null
    ) {
      fetchdata(JSON.parse(storedData));
    }
  }, []);

  const fetchdata = async (params) => {
    setUserDetails(JSON.parse(sessionStorage.getItem("UserData")));
    const temp = sessionStorage.getItem("HomePageNotification");
    const parsedTemp = temp ? JSON.parse(temp) : null;
    const responsedata = await ConstructorEventGet(
      FetchHomePageDetails,
      params
    );
    setUserDetails(responsedata[0]);
  };

  const Dropdownselect = (event) => {
    var selectedvalue = event.target.value;
    setSelectedOption(selectedvalue);
  };

  // Handle dropdown change event
  const handleColorChange = (event) => {
    console.log(event.target.value);
    if (activitystarted && selectedOption == "Click") {
      setSelectedColor(event.target.value);
    } else {
      console.log(selectedOption);
    }
  };

  const handleDragStart = (e) => {
    if (activitystarted === true) {
      e.dataTransfer.setData("text/plain", e.target.id);
      setDragging(true);
    }
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData("text/plain");
    const droppedItem = document.getElementById(droppedItemId);

    if (droppedItem instanceof HTMLElement) {
      // Check if droppedItem is a valid DOM node
      setDivtochangeContent([...divtochangeContent, droppedItemId]);
      e.target.appendChild(droppedItem);
      setDragging(false);
    } else {
      console.error(`Element with ID ${droppedItemId} not found or not valid.`);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const dataone = {
    Sound:
      "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/original-phone-ringtone-36558.mp3",

    Drum: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/grunge-bounce-drum-loop-40464.mp3",

    Rain: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/heavy-rain-nature-sounds-8186.mp3",

    Guitar:
      "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/guitar-riff-159089.mp3",
  };

  const data = {
    Left: {
      background_color: ["black", "yellow", "pink"],
    },
    Right: { background_color: ["red", "blue", "green"] },
  };

  const PlayAudio = (audio) => {
    var audio = new Audio(audio);
    audio.play();
  };
  const handleMobileClick = () => {
    // Handle DesignVew22 click logic
    setMobileClicked(true);
    if (activitystarted && selectedOption === "Click" && isMobileClicked) {
      var audio = new Audio(selectedAudio);
      audio.play();
      setScreen(SelectedScreen);
      if (propertyScreen && selectedPropertyColor && valuePropertyDropdown) {
        console.log(propertyScreen);
        console.log(selectedPropertyColor);
        console.log(valuePropertyDropdown);
        setSelectedScreen(propertyScreen);
        setSelectedColor(selectedPropertyColor);
        setValueDropdown(valuePropertyDropdown);
        setScreen(propertyScreen);
      }
    }
  };

  function handleHtmlCode() {
    const data = getHtmlCode.current.outerHTML;
    console.log("The extracted HTML code is----", data);
    setHtmlCode(data);
  }

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  const [htmlStructure, setHtmlStructure] = useState();

  function getHtmlStructure() {
    const data = getHtmlCode.current.outerHTML;

    setHtmlStructure(`<!DOCTYPE html>
    <html>
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width">
      <title>My App</title>
      <style>
      .design-vew22 {
        background: url("https://d3e3enpv2x7kmj.cloudfront.net/TestImages/mobile.png");
        width: 100%;
        max-width: 280px;
        height: 555px;
        background-size: cover;
        padding: 50px 20px;
        margin-top: 15px;
        margin-bottom:50px
      }
      .buttonsessionActivity10 {
        height: 60px;
        width: 90px;
        border: 1px solid #007bff;
        background-color: green;
        color: wheat;
        border-radius: 10px;
        margin-top: 300px !important;
        margin-left: 100px !important;
      }
      .appLabSession10 {
        height: 115.5%; 
        width: 108%; 
       border-radius: 40px; 
       margin-top: -43px;
       margin-left: -11px; }
      
      </style>
    </head>
    <body>
      ${data}
    </body>
    </html>`);

    const htmlStructure = `<!DOCTYPE html>
    <html>
    <head>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width">
      <title>My App</title>
      <style>
      .design-vew22 {
        background: url("https://d3e3enpv2x7kmj.cloudfront.net/TestImages/mobile.png");
        width: 100%;
        max-width: 280px;
        height: 555px;
        background-size: cover;
        padding: 50px 20px;
        margin-top: 15px;
        margin-bottom:50px
      }
      .buttonsessionActivity10 {
        height: 60px;
        width: 90px;
        border: 1px solid #007bff;
        background-color: green;
        color: wheat;
        border-radius: 10px;
        margin-top: 300px !important;
        margin-left: 100px !important;
      }
      .appLabSession10 {
        height: 115.5%; 
        width: 108%; 
       border-radius: 40px; 
       margin-top: -43px;
       margin-left: -11px; }
      
      </style>
    </head>
    <body>
      ${data}
    </body>
    </html>`;

    console.log("the extracted htmlStructure is----", htmlStructure);

    const htmlWithoutNewlines = htmlStructure.replace(/\n/g, "");

    // Remove both single and consecutive backslashes
    const htmlWithoutBackslashes = htmlWithoutNewlines.replace(/\\+/g, "");

    // Data to be sent to the Lambda function
    const additionalData = `${userDetails.user_name}-(${userDetails.course[0].batch_id})-AppLabSessionNine MobileApp`;

    const dataToStore = {
      name: htmlWithoutBackslashes,
    };

    fetch(SaveHtmlStructure, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event: dataToStore,
        additionalData: additionalData,
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));
  }

  return (
    <div class="container">
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="Newbody">
            <div className="Newheader">
              <div
                className="design-vew22"
                ref={getHtmlCode}
                onClick={handleMobileClick}
              >
                {screen === "Left" && activitystarted ? (
                  <div
                    className={
                      SelectedScreen == "Left" &&
                      activitystarted &&
                      selectedColor == "background_color"
                        ? "appLabSession10"
                        : null
                    }
                    style={{
                      backgroundColor:
                        SelectedScreen == "Left" &&
                        activitystarted &&
                        selectedColor == "background_color"
                          ? ValueDropdown
                          : "white",
                    }}
                  >
                    <button
                      className="buttonsessionActivity10"
                      style={{
                        backgroundColor:
                          activitystarted && valuePropertyDropdown === "green"
                            ? "yellow"
                            : null,
                        color:
                          activitystarted && valuePropertyDropdown === "green"
                            ? "black"
                            : null,
                        marginTop:
                          activitystarted && SelectedScreen === "Left"
                            ? "230px"
                            : "185px",
                        marginLeft:
                          activitystarted && SelectedScreen === "Left"
                            ? "75px"
                            : "65px",
                      }}
                    >
                      Left Screen
                    </button>
                  </div>
                ) : screen === "Right" && activitystarted ? (
                  <div
                    className={
                      SelectedScreen == "Right" &&
                      activitystarted &&
                      selectedColor == "background_color"
                        ? "appLabSession10"
                        : null
                    }
                    style={{
                      backgroundColor:
                        SelectedScreen == "Right" &&
                        activitystarted &&
                        selectedColor == "background_color"
                          ? ValueDropdown
                          : "white",
                    }}
                  >
                    <button
                      className="buttonsessionActivity10"
                      style={{
                        backgroundColor:
                          activitystarted && valuePropertyDropdown === "green"
                            ? "yellow"
                            : null,
                        color:
                          activitystarted && valuePropertyDropdown === "green"
                            ? "black"
                            : null,
                        marginTop:
                          activitystarted && SelectedScreen === "Right"
                            ? "230px"
                            : "185px",
                        marginLeft:
                          activitystarted && SelectedScreen === "Right"
                            ? "75px"
                            : "55px",
                      }}
                    >
                      Right Screen
                    </button>
                  </div>
                ) : (
                  <button
                    className="buttonsessionActivity10"
                    style={{
                      marginTop:
                        activitystarted && SelectedScreen === "Left"
                          ? "230px"
                          : "185px",
                      marginLeft:
                        activitystarted && SelectedScreen === "Right"
                          ? "75px"
                          : "65px",
                    }}
                  >
                    {screen === "Left" ? "Left Screen" : "Right Screen"}
                  </button>
                )}
              </div>
              <button
                className="button-12 mt-2"
                style={{ textAlign: "center" }}
                onClick={() =>
                  activitystarted
                    ? (setClickedNext(false), setActivitystarted(false))
                    : setActivitystarted(true)
                }
              >
                {activitystarted ? "RESET" : "RUN"}
              </button>
            </div>
          </div>
        </div>
        <div class="lg:col-span-9 col-span-12 mt-5">
          <i class="fa-duotone fa-up-down-left-right fa-spin fa-spin-reverse"></i>
          <div className="NavbarApp">
            &emsp;Instructions
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <span></span>

              <button className="getHtmlCodeBtn" onClick={getHtmlStructure}>
                <b>Share App</b>
              </button>
            </div>
          </div>
          <div>
            <div className="fontsp">Goal:</div>
            <h6>
              &emsp;&emsp;Build your own app by extending this project. Then
              "Share" it with someone!
            </h6>
            {/* <div className='fontsp'>How:</div><h6>&emsp;&emsp; Add code inside the onEvent() that sets the screen's "background-color" to "green". </h6> */}
          </div>

          <div className=" row ">
            <div className="col-6">
              <div class="box">
                <div class="box-head">
                  &emsp;Toolbox
                  <a class="yellow-btn">
                    <i class="fa-solid fa-minus"></i>
                  </a>
                  <iconify-icon
                    icon="icon-park-twotone:right-c"
                    width="20"
                    class="iconify-icon-right"
                  ></iconify-icon>
                  <iconify-icon
                    icon="ant-design:setting-outlined"
                    width="20"
                    class="iconify-icon-left"
                  ></iconify-icon>
                  {}
                </div>
              </div>

              <div class="box-content p-3">
                <div class="controlhead">UI Controls</div>
                <div>&emsp;</div>
                <div onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                  <div
                    class={selectedpopup ? null : "pin"}
                    draggable="true"
                    id="1"
                  >
                    <div
                      class="divtochange flex"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      setProperty
                      {!divtochangeContent.includes("1") &&
                        "(id,property,value)"}
                      {divtochangeContent.includes("1") && (
                        <div>
                          <select
                            class="dropdown"
                            onChange={(e) => setPropertyScreen(e.target.value)}
                            value={propertyScreen}
                          >
                            <option value="">Select an option</option>
                            <option value="Left">Left</option>
                            <option value="Right">Right</option>
                          </select>
                          <select
                            class="selectinpin"
                            onChange={(e) =>
                              setPropertySelectedColor(e.target.value)
                            }
                            value={selectedPropertyColor}
                          >
                            <option value="">Select an option</option>
                            {data &&
                              data[propertyScreen] &&
                              Object.keys(data[propertyScreen]).map(
                                (color, index) => (
                                  <option value={color}>{color}</option>
                                )
                              )}
                          </select>

                          <select
                            class="selectinpin"
                            onChange={(e) =>
                              setPropertyValueDropdown(e.target.value)
                            }
                            value={valuePropertyDropdown}
                          >
                            <option value="">Select an option</option>
                            {data[propertyScreen] &&
                              data[propertyScreen][selectedPropertyColor] &&
                              data[propertyScreen][selectedPropertyColor].map(
                                (Screen, index) => (
                                  <option value={Screen}>{Screen}</option>
                                )
                              )}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    class={selectedpopup ? null : "pin"}
                    draggable="true"
                    id="3"
                  >
                    <div
                      class="divtochange flex"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      SetScreen
                      {!divtochangeContent.includes("3") && "( screenid )"}
                      {divtochangeContent.includes("3") && (
                        <div>
                          (
                          <select
                            class="dropdown"
                            onChange={(e) => setSelectedScreen(e.target.value)}
                            value={SelectedScreen}
                          >
                            <option value="">Select an option</option>
                            <option value="Left">Left</option>
                            <option value="Right">Right</option>
                          </select>
                          )
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    class={selectedpopup ? null : "pin"}
                    draggable="true"
                    id="4"
                  >
                    <div
                      class="divtochange flex"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Playsound{!divtochangeContent.includes("4") && "( URL )"}
                      {divtochangeContent.includes("4") && (
                        <div>
                          (
                          <select
                            class="dropdown"
                            onChange={(e) => setSelectedAudio(e.target.value)}
                            value={selectedAudio}
                          >
                            <option value="">Select</option>
                            {dataone &&
                              Object.keys(dataone).map((item, index) => (
                                <option value={dataone[item]}>{item}</option>
                              ))}
                          </select>
                          )
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    class=" draggable"
                    draggable="true"
                    id="2"
                    onDragStart={handleDragStart}
                  >
                    <div class="box-content p-3">
                      <div
                        class={selectedpopup ? null : "plug-board work-space"}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                      >
                        <div class="plug-board-top">
                          OnEvent
                          <select
                            className="dropdown"
                            onChange={Dropdownselect}
                          >
                            <option value="nonClick">Select an option</option>
                            <option value="Click">Click</option>
                          </select>
                        </div>

                        <div class="plug">
                          <div
                            class="plug-blue"
                            ondrop="drop(event)"
                            ondragover="allowDrop(event)"
                          ></div>
                        </div>

                        <div class="plug-board-bottom">
                          <div class="bottom-blue"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9">
              <div class="box">
                <div class="box-head">
                  &emsp;Workspace
                  <a
                    class="btn"
                    onClick={() => {
                      popupvalue();
                      handleHtmlCode();
                      getHtmlStructure();
                    }}
                  >
                    <button>Show Code</button>
                  </a>
                  {"  "}
                  <a
                    class="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#show-code-popup"
                  >
                    <button>Version History</button>
                  </a>
                  {selectedpopup && (
                    <div className="modal">
                      <div className="overlay">
                        <div className="modal-content">
                          {htmlCode ? htmlStructure : null}
                          <button
                            className="btn"
                            style={{ marginTop: "30px" }}
                            onClick={popupvalue}
                          >
                            close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  class="box-content p-3"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div class="plug-board work-space" id="drop-zone"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appone;
