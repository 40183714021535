import React,{useRef} from "react";
import { useLocation } from "react-router-dom";
import { call, mail, map } from "../../constant/images";
import Header from "../Header";
import ContactForm from "./ContactForm";
const ContactUs = (props) => {
  const location = useLocation()
  const data = useRef(null)
  data.current = location.state ? location.state : data.current
  
  return (
    <>
    <Header />
      <div className="nav-tab-wrapper tabs  section-padding">
        <div className="container">
          <div className=" grid grid-cols-12 gap-[30px]">
            <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
              <div className="mini-title">Contact Us</div>
              <h4 className="column-title ">
                Get In Touch <span className="shape-bg">Today</span>
              </h4>
              <div>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered.
              </div>
              <ul className=" list-item space-y-6 pt-8">
                <li className="flex">
                  <div className="flex-none mr-6">
                    <div className="">
                      <img src={mail} alt="" className="" />
                    </div>
                  </div>
                  <div className="flex-1">
                    <h4 className=" lg:text-xl text-lg mb-1">Email-Us :</h4>
                    <div>info@codomo.in</div>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex-none mr-6">
                    <div className="">
                      <img src={call} alt="" className="" />
                    </div>
                  </div>
                  <div className="flex-1">
                    <h4 className=" lg:text-xl text-lg mb-1">Call Us:</h4>
                    <div>+91 9150219789</div>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex-none mr-6">
                    <div className="">
                      <img src={map} alt="" className="" />
                    </div>
                  </div>
                  <div className="flex-1">
                    <h4 className="lg:text-xl text-lg mb-1">Office :</h4>
                    <div>88/3,3rd Floor,Rathinam Circle View,Race Course Road, Coimbatore, Tamil Nadu, 641018

                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="xl:col-span-7 lg:col-span-6 col-span-12">
              <div className="bg-white shadow-box7 p-8 rounded-md">
                <ContactForm data={data.current? data.current : null}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
