import Lottie from "lottie-react";
import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/newacitivity.css";
import animationData from "../../assets/animation/animation_lnee9hpc.json";

export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();

  const [questionNumber, setQuestionNumber] = useState(0);
  const [Activitystarted, setActivitystarted] = useState(false);
  const [SelectedFontsize, setSelectedFontsize] = useState("16px");
  const [selectedColor, setSelectedColor] = useState("");
  const [workspaceItems, setWorkspaceItems] = useState([]);
  const [ClickedNext, setClickedNext] = useState(false);
  const [selectedColorbutton, setSelectedColorbutton] = useState("red");
  const [selectedAudio, setSelectedAudio] = useState("");
  const [dragging, setDragging] = useState(false);
  const [ValueDropdown, setValueDropdown] = useState("");
  const [divtochangeContent, setDivtochangeContent] = useState([]);
  const [SelectedScreen, setSelectedScreen] = useState("");
  const [selectedpopup, setSelectedpopup] = useState(false);

  const handleFontsize = (event) => {
    const selectedOption = event.target.value;
    setSelectedFontsize(selectedOption);
  };

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
    if (Activitystarted == true) {
      setSelectedColor(event.target.value);
    }
  };
  const handleColorbuttonChange = (e) => {
    setSelectedColorbutton(e.target.value); // Update the selected color state
  };
  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", e.target.id);
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData("text/plain");
    const droppedItem = document.getElementById(droppedItemId);
    setDivtochangeContent([...divtochangeContent, droppedItemId]);
    // const clone = droppedItem.cloneNode(true);
    e.target.appendChild(droppedItem);
    setDragging(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    //         const droppedItemId = e.dataTransfer.getData('text/plain');
    //   const droppedItem = document.getElementById(droppedItemId);
    //   e.target.removeChild(droppedItem);
  };

  const colorMap = {
    Red: "red",
    Green: "green",
    Blue: "blue",
    Yellow: "yellow",
  };
  const screenMap = {
    one: "../images/NewActivityImages/mobile3.jpg",
    two: "../images/NewActivityImages/mobile4.jpg",
    three: "../images/NewActivityImages/mobile5.jpg",
    four: "../images/NewActivityImages/Project.jpg",
  };

  const divStyle = {
    // buttonsession: colorMap[selectedColorbutton] || "white", // Default to white if no color selected
    width: "100%",
    height: "600px",
    borderRadius: "30px",
  };

  const data = {
    Button1: {
      image: [
        "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/Beautiful_White_Horse.jpg",
      ],
    },
    Button2: {
      image: [
        "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/Lion-HD-Images-Pictures-Wallpapers-Pictures-Images-.jpg",
      ],
    },
    Button3: {
      image: ["https://d3e3enpv2x7kmj.cloudfront.net/TestImages/monkey.jpg"],
    },
    Button4: {
      image: ["https://d3e3enpv2x7kmj.cloudfront.net/TestImages/gray-wolf.jpg"],
    },
  };
  const dataone = {
    Horse: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/horse-123780.mp3",

    Lion: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/lion-roar-6011.mp3",

    Monkey:
      "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/monkey-128368.mp3",

    Wolf: "https://d3e3enpv2x7kmj.cloudfront.net/TestImages/wolf-howl-6310.mp3",
  };
  const PlayAudio = (audio) => {
    if (Activitystarted === true) {
      var audio = new Audio(audio);
      audio.play();
    }
  };

  useEffect(() => {
    if (Activitystarted === true && selectedAudio) {
      var audio = new Audio(selectedAudio);
      audio.play();
    } else {
      var audio = new Audio(selectedAudio);
      audio.pause();
      audio.currentTime = 0;
    }
  }, [Activitystarted, selectedAudio]);

  function handleHtmlCode() {
    const data = getHtmlCode.current.outerHTML;
    console.log("The extracted HTML code is----", data);
    setHtmlCode(data);
  }

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  return (
    <div class="container" style={{ overflow: "hidden" }}>
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="Newbody">
            <div className="Newheader">
              <div className="design-vew22" ref={getHtmlCode}>
                <div className="toolboxcss" style={divStyle}>
                  <div>
                    <Lottie animationData={animationData} />{" "}
                  </div>

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                    }}
                  >
                    <button
                      className="buttonsession1"
                      style={{
                        backgroundColor:
                          selectedColorbutton == "Button1" &&
                          Activitystarted &&
                          selectedColor == "image"
                            ? ValueDropdown
                            : "#E8E5D5",
                      }}
                    >
                      {selectedColorbutton == "Button1" &&
                      Activitystarted &&
                      selectedColor == "image" &&
                      ValueDropdown ? (
                        <img
                          src={ValueDropdown}
                          className="activitySixBtnImg"
                          alt=""
                        ></img>
                      ) : (
                        <p>Horse</p>
                      )}
                    </button>
                    <button
                      className="buttonsession1"
                      style={{
                        backgroundColor:
                          selectedColorbutton == "Button2" &&
                          Activitystarted &&
                          selectedColor == "button Color"
                            ? ValueDropdown
                            : "#E8E5D5",
                      }}
                    >
                      {selectedColorbutton == "Button2" &&
                      Activitystarted &&
                      selectedColor == "image" &&
                      ValueDropdown ? (
                        <img
                          src={ValueDropdown}
                          className="activitySixBtnImg"
                          alt=""
                        ></img>
                      ) : (
                        <p>Lion</p>
                      )}
                    </button>
                    <button
                      className="buttonsession1"
                      style={{
                        backgroundColor:
                          selectedColorbutton == "Button3" &&
                          Activitystarted &&
                          selectedColor == "button Color"
                            ? ValueDropdown
                            : "#E8E5D5",
                      }}
                    >
                      {selectedColorbutton == "Button3" &&
                      Activitystarted &&
                      selectedColor == "image" &&
                      ValueDropdown ? (
                        <img
                          src={ValueDropdown}
                          className="activitySixBtnImg"
                          alt=""
                        ></img>
                      ) : (
                        <p>Monkey</p>
                      )}
                    </button>
                    <button
                      className="buttonsession1"
                      style={{
                        backgroundColor:
                          selectedColorbutton == "Button4" &&
                          Activitystarted &&
                          selectedColor == "button Color"
                            ? ValueDropdown
                            : "#E8E5D5",
                      }}
                    >
                      {selectedColorbutton == "Button4" &&
                      Activitystarted &&
                      selectedColor == "image" &&
                      ValueDropdown ? (
                        <img
                          src={ValueDropdown}
                          className="activitySixBtnImg"
                          alt=""
                        ></img>
                      ) : (
                        <p>Wolf</p>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {/* <div class="nav-controls2 d-flex justify-content-between align-items-center"> */}
              <button
                className="button-12 mt-2 "
                style={{ textAlign: "center" }}
                onClick={() =>
                  Activitystarted
                    ? (setClickedNext(false), setActivitystarted(false))
                    : setActivitystarted(true)
                }
              >
                {Activitystarted ? "RESET" : "RUN"}
              </button>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div class="lg:col-span-9 col-span-12 mt-5">
          <div className="NavbarApp">&emsp;Instructions</div>{" "}
          <h6>
            Goal: This sound board is almost done! Add an image, text, and sound
            to the last button to see how it works.
          </h6>
          <h6>
            How : Add an onEvent() to the program and set the "id" to "button4".
            Drag a playSound() into the onEvent() and choose a sound from the
            library.
          </h6>
          <h6>
            Add a setProperty() (outside your onEvent()) to change the button's
            "image" property. Choose an image using the third dropdown.
          </h6>
          <h6>Add one more setProperty() .</h6>
          <div className="row">
            <div className="col-6">
              <div class="box">
                <div class="box-head">
                  <a class="yellow-btn">
                    <i class="fa-solid fa-minus"></i>
                  </a>
                  <h2>TOOLBOX</h2>
                </div>
                <div class="box-content p-3">
                  <div class="box-content p-3">
                    <div class="controlhead">UI Controls</div>
                    <div>&emsp;</div>
                    <div
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                    >
                      <div
                        class={selectedpopup ? "mb-4" : "pin mb-4"}
                        draggable="true"
                        id="1"
                      >
                        <div
                          class="divtochange flex"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          setProperty
                          {!divtochangeContent.includes("1") &&
                            "(id,property,value)"}
                          {divtochangeContent.includes("1") && (
                            <div>
                              (
                              <select
                                className="selectinpin"
                                onChange={(e) => (
                                  setSelectedColorbutton(e.target.value),
                                  setSelectedColor(""),
                                  setValueDropdown("")
                                )}
                                value={selectedColorbutton}
                              >
                                <option value="">Select a Button</option>
                                {data &&
                                  Object.keys(data).map((item, index) => (
                                    <option value={item}>{item}</option>
                                  ))}
                              </select>
                              <select
                                className="selectinpin"
                                onChange={(e) =>
                                  setSelectedColor(e.target.value)
                                }
                                value={selectedColor}
                              >
                                <option value="">Select an Option</option>
                                {data &&
                                  data[selectedColorbutton] &&
                                  Object.keys(data[selectedColorbutton]).map(
                                    (Screen, index) => (
                                      <option value={Screen}>{Screen}</option>
                                    )
                                  )}
                              </select>
                              <select
                                className="selectinpin"
                                onChange={(e) =>
                                  setValueDropdown(e.target.value)
                                }
                                value={ValueDropdown}
                              >
                                <option value=""></option>
                                {data &&
                                  data[selectedColorbutton] &&
                                  data[selectedColorbutton][selectedColor] &&
                                  data[selectedColorbutton][selectedColor].map(
                                    (item1, index) => (
                                      <option value={item1}>{item1}</option>
                                    )
                                  )}
                              </select>
                              )
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <div class="box-content " onDragStart={handleDragStart}
                                                onDragEnd={handleDragEnd}> */}
                      <div
                        class={selectedpopup ? "mb-2" : "pin mb-2"}
                        draggable="true"
                        id="3"
                      >
                        <div className="divtochange flex">
                          <h5 style={{ color: selectedpopup ? "grey" : null }}>
                            Play Sound{" "}
                          </h5>
                          (
                          <select
                            class="selectinpin"
                            onChange={
                              (e) => setSelectedAudio(e.target.value)
                              // PlayAudio(e.target.value)
                            }
                            value={selectedAudio}
                          >
                            <option value="">Select</option>
                            {dataone &&
                              Object.keys(dataone).map((item, index) => (
                                <option value={dataone[item]}>{item}</option>
                              ))}
                          </select>
                          )
                        </div>
                      </div>
                      <div class="box-content p-3" draggable="true" id="2">
                        <div
                          class={selectedpopup ? null : "plug-board work-space"}
                          onDrop={(e) => Activitystarted && handleDrop(e)}
                          onDragOver={(e) =>
                            Activitystarted && handleDragOver(e)
                          }
                        >
                          <div class="plug-board-top">
                            OnEvent (id, type, callback)
                            <div class="function">Function</div>
                          </div>
                          <div class="plug">
                            <div
                              class="plug-blue"
                              ondrop="drop(event)"
                              onDrop={handleDrop}
                              onDragOver={handleDragOver}
                              ondragover="allowDrop(event)"
                            ></div>
                          </div>
                          <div class="plug-board-bottom">
                            <div class="bottom-blue"></div>
                          </div>
                        </div>
                      </div>

                      {/* </div> */}

                      {/* <label htmlFor="fontSizeSelect">Select Font Size:</label>
                                        <select id="fontSizeSelect" onChange={handleFontsize}>
                                            <option value="16px">10px</option>
                                            <option value="20px">16px</option>
                                            <option value="24px">24px</option>
                                            <option value="24px">30px</option>
                                            {/* Add more font size options as needed */}
                      {/* </select> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9">
              <div class="box">
                <div class="box-head">
                  &emsp;Workspace
                  <a
                    class="btn "
                    onClick={() => {
                      popupvalue();
                      handleHtmlCode();
                    }}
                  >
                    <button> Show Code</button>
                  </a>{" "}
                  <a
                    class="btn me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#show-code-popup"
                  >
                    <button> Version History</button>
                  </a>
                  {selectedpopup && (
                    <div className="modal">
                      <div className="overlay">
                        <div
                          className="modal-content"
                          style={{ height: "200px", overflow: "scroll" }}
                        >
                          {htmlCode ? htmlCode : null}
                          <button className="btn" onClick={popupvalue}>
                            close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  class="box-content p-3"
                  onDrop={(e) => Activitystarted && handleDrop(e)}
                  onDragOver={(e) => Activitystarted && handleDragOver(e)}
                >
                  <div class="plug-board work-space" id="drop-zone"></div>
                </div>
                <div class="box-content p-3">
                  <div>
                    <div
                      class="box-content p-3"
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                    >
                      <div class="plug-board work-space" id="drop-zone"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="nav-controls2 d-flex justify-content-between align-items-center"> */}

            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appone;
