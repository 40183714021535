import React, { useState, useRef } from "react";
import "../../assets/css/newacitivity.css";

export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();

  const [questionNumber, setQuestionNumber] = useState(0);
  const [Activitystarted, setActivitystarted] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [SelectedScreen, setSelectedScreen] = useState("");
  const [ClickedNext, setClickedNext] = useState(false);
  const [dragging, setDragging] = useState(true);
  const [divtochangeContent, setDivtochangeContent] = useState([]);
  const [ValueDropdown, setValueDropdown] = useState("");
  const [SelectedFontsize, setSelectedFontsize] = useState("");
  const [selectedpopup, setSelectedpopup] = useState(false);

  // Handle dropdown change event
  const handleColorChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedColor(event.target.value);
    }
  };
  const handleAttributeChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedAttribute(event.target.value);
    }
  };
  const handleScreenChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedScreen(event.target.value);
    }
  };
  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", e.target.id);
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData("text/plain");
    const droppedItem = document.getElementById(droppedItemId);
    // Update the content of divtochangeContent here based on the drop event
    setDivtochangeContent([...divtochangeContent, droppedItemId]);
    e.target.appendChild(droppedItem);
    setDragging(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const colorMap = {
    Red: "red",
    Green: "green",
    Blue: "blue",
    Yellow: "yellow",
  };
  const screenMap = {
    one: "../images/NewActivityImages/mobile3.jpg",
    two: "../images/NewActivityImages/mobile4.jpg",
    three: "../images/NewActivityImages/mobile5.jpg",
    four: "../images/NewActivityImages/Project.jpg",
  };
  const labelMap = {
    "16px": "red",
    "16px": "green",
    "20px": "blue",
    "24px": "yellow",
    "16px": "yellow",
  };
  const handleFontsize = (event) => {
    const selectedOption = event.target.value;
    setSelectedFontsize(selectedOption);
  };
  const handleclick = () => {
    setSelectedColor("background_color");
    setValueDropdown("green");
  };

  const divStyle = {
    backgroundColor:
      selectedColor == "background_color" && Activitystarted
        ? ValueDropdown
        : "white",
    fontSize:
      selectedColor == "fontSize" && Activitystarted
        ? `${ValueDropdown}px`
        : "5px",
    height: "121.5%", // Inherit the height from the parent
    width: "111%",
    borderRadius: "30px",
    marginLeft: "-11px",
    marginTop: "-45px",
  };
  const data = {
    Label: {
      background_color: ["red", "blue", "green"],
      fontSize: ["8", "15", "20"],
    },
    Screen: { background_color: ["red", "blue", "pink", "yellow"] },
  };

  function handleHtmlCode() {
    const data = getHtmlCode.current.outerHTML;
    console.log("The extracted HTML code is----", data);
    setHtmlCode(data);
  }

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  return (
    <div class="container">
      <div></div>
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="Newbody">
            <div className="Newheader">
              <div className="design-vew22" ref={getHtmlCode}>
                <div style={divStyle}>
                  <div>
                    {Activitystarted ? (
                      <p style={{ padding: "20px" }}>Make Me Bigger.........</p>
                    ) : null}
                  </div>
                  {selectedColor && <p>{} </p>}
                  {SelectedScreen && <p>{} </p>}
                  <button
                    class="greenifybutton"
                    style={{ marginTop: Activitystarted ? "390px" : "457px" }}
                    onClick={() => Activitystarted && handleclick()}
                  >
                    Greenify
                  </button>
                </div>
              </div>
              {/* <div class="nav-controls2 d-flex justify-content-between align-items-center"> */}
              <button
                className="button-12 mt-2"
                style={{ textAlign: "center" }}
                onClick={() =>
                  Activitystarted
                    ? (setClickedNext(false), setActivitystarted(false))
                    : setActivitystarted(true)
                }
              >
                {Activitystarted ? "RESET" : "RUN"}
              </button>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div class="lg:col-span-9 col-span-12 mt-5">
          <i class="fa-duotone fa-up-down-left-right fa-spin fa-spin-reverse"></i>
          <div className="NavbarApp">&emsp;Instructions</div>
          <div>
            <div className="fontsp">Goal:</div>
            <h6>
              &emsp;&emsp;The screen will start out blue. Add code so it turns
              green when you click the "Green-ify" button.
            </h6>
            <div className="fontsp">How:</div>
            <h6>
              &emsp;&emsp; Add code inside the onEvent() that sets the screen's
              "background-color" to "green".{" "}
            </h6>
          </div>

          <div className=" row ">
            <div className="col-6 ">
              <div class="box">
                <div class="box-head">
                  &emsp;Toolbox
                  <a class="yellow-btn">
                    <i class="fa-solid fa-minus"></i>
                  </a>
                  <iconify-icon
                    icon="icon-park-twotone:right-c"
                    width="20"
                    class="iconify-icon-right"
                  ></iconify-icon>
                  <iconify-icon
                    icon="ant-design:setting-outlined"
                    width="20"
                    class="iconify-icon-left"
                  ></iconify-icon>
                  {}
                </div>
              </div>

              <div class="box-content p-3">
                <div class="controlhead">UI Controls</div>
                <div>&emsp;</div>
                <div onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                  <div
                    class={selectedpopup ? null : "pin"}
                    draggable="true"
                    id="12"
                  >
                    <div
                      class="divtochange flex"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      setProperty
                      {!divtochangeContent.includes("12") &&
                        "(id,property,value)"}
                      {divtochangeContent.includes("12") && (
                        <div>
                          (
                          <select
                            class="selectinpin"
                            onChange={handleScreenChange}
                            value={SelectedScreen}
                          >
                            <option value=""></option>
                            {Object.keys(data).map((Screen, index) => (
                              <option value={Screen}>{Screen}</option>
                            ))}
                          </select>
                          <select
                            class="selectinpin"
                            onChange={handleColorChange}
                            value={selectedColor}
                          >
                            <option value="">Select an option</option>
                            {data &&
                              data[SelectedScreen] &&
                              Object.keys(data[SelectedScreen]).map(
                                (Screen, index) => (
                                  <option value={Screen}>{Screen}</option>
                                )
                              )}
                          </select>
                          <select
                            class="selectinpin"
                            onChange={(e) => setValueDropdown(e.target.value)}
                            value={ValueDropdown}
                          >
                            <option value="">Select an option</option>
                            {data[SelectedScreen] &&
                              data[SelectedScreen][selectedColor] &&
                              data[SelectedScreen][selectedColor].map(
                                (Screen, index) => (
                                  <option value={Screen}>{Screen}</option>
                                )
                              )}
                          </select>
                          )
                        </div>
                      )}
                    </div>
                  </div>
                  {}
                  <div class=" draggable" draggable="true" id="8">
                    <div class="box-content p-3">
                      <div
                        class={selectedpopup ? null : "plug-board work-space"}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                      >
                        <div class="plug-board-top">
                          OnEvent (id, type, callback)
                          <div class="function">Function</div>
                        </div>

                        <div class="plug">
                          <div
                            class="plug-blue"
                            ondrop="drop(event)"
                            ondragover="allowDrop(event)"
                          ></div>
                        </div>

                        <div class="plug-board-bottom">
                          <div class="bottom-blue"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9">
              <div class="box">
                <div class="box-head">
                  &emsp;Workspace
                  <a
                    class="btn "
                    onClick={() => {
                      popupvalue();
                      handleHtmlCode();
                    }}
                  >
                    <button> Show Code</button>
                  </a>{" "}
                  <a
                    class="btn me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#show-code-popup"
                  >
                    <button> Version History</button>
                  </a>
                  {selectedpopup && (
                    <div className="modal">
                      <div className="overlay">
                        <div className="modal-content">
                          {htmlCode ? htmlCode : null}
                          <button
                            className="btn"
                            style={{ marginTop: "30px" }}
                            onClick={popupvalue}
                          >
                            close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  class="box-content p-3"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div class="plug-board work-space" id="drop-zone"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appone;
