import React, { useState,useEffect } from "react";
import {Link,useNavigate} from "react-router-dom";
import Header from "../Header";
import { FetchBatchData , updateBatchStatus} from "../../api/aws-api";
import { ConstructorEventGet,ConstructorEventPost } from "../../Event/event";
import { ClassicSpinner} from "react-spinners-kit";
import Swal from 'sweetalert2'
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';


const MentorActivities = () => {
    const [batch,setBatch] = useState([])
    const [loading,setLoading] = useState(false)
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [status,setStatus] = useState("Scheduled")
    const navigate = useNavigate()
    useEffect(()=>{
        FetchBatchList()
      },[])
    const FetchBatchList = async () =>{
      setLoading(true)
      const id = JSON.parse(localStorage.getItem('UserKey'))["user_id"];
      const responsedata = await ConstructorEventGet (FetchBatchData,{"mentorId" : id})
      console.log(responsedata)
      setBatch(responsedata ? responsedata : [])
      setLoading(false)
    }
    /*const UpdateBatchProgress = (batch,statusupdate) => {
      const params = {
        "mentorId" : batch.mentorId,
        "BatchName" : batch.BatchName,
        "batchProgress" : statusupdate
      }
      Swal.fire({
        title: statusupdate === "Cancelled" ? "Do you wish to cancel this batch?" : statusupdate === "Discontinued" ? "Would you like to Discontinue this batch?" : 
        statusupdate === "InProgress" ? "Would you like to switch the status of this batch to in progress?" : statusupdate === "Completed" ? "Would you like to switch the status of this batch to Completed ?" : "",
        text: statusupdate === "Cancelled" ? "Cancel " + batch.BatchName + " ?" : statusupdate === "Discontinued" ? "Discontinue " + batch.BatchName + " ?" : 
        statusupdate === "InProgress" ? "Started " + batch.BatchName + " ?" : statusupdate === "Completed" ? "Completed" + batch.BatchName + " ?" : "",
        icon: statusupdate === "InProgress" ? "success" :statusupdate === "Completed" ? "success" : "warning",
        iconColor : statusupdate === "InProgress" ?  "#4df766" :statusupdate === "Completed" ?  "#4df766" : "#fa414a",
        confirmButtonText: 'Yes, please',
        confirmButtonColor : "#FF7E84",
        cancelButtonColor : "#30BEAD",
        showCancelButton : true,
        cancelButtonText:"Nope,Changed my mind",
      }).then((result) => {
        if (result.isConfirmed) {
          if (statusupdate === "InProgress"){
            Swal.fire({
              title: 'Enter Meet Link',
              html:
                '<input id="swal-input" type="text" class="swal2-input from-control-1" placeholder="Enter Class Meet Link" >',
              showCancelButton: true,
              confirmButtonText: 'Proceed',
              cancelButtonText: 'Cancel',
              confirmButtonColor : "#FF7E84",
              cancelButtonColor : "#30BEAD",
              preConfirm: () => {
                const input = document.getElementById('swal-input');
                if (input) {
                  params["meet_link"] = input.value
                }
              }
            }).then((confirm) => {if (confirm.isConfirmed) {
                updateDatainCloud(params)
            }})
          }
          else{updateDatainCloud(params)}
        }
      });
    }
    const updateDatainCloud = async(params) => {
      setLoading(true)
      const responsedata = await ConstructorEventPost(updateBatchStatus,params)
      if (responsedata[0] === "updated"){setLoading(false);setBatch(responsedata[1])}
      else {setLoading(false)}

    }*/

  return (
    <>
    <Header/>
    {loading ? 
    <div className="spinner" >
    <div className="spinner-wrapper">
      <ClassicSpinner size={50} color ="black" loading={loading} />
      </div></div> :
    <div className=" section-padding">
      <div className="container">
        <div className="text-center">
          <div className="mini-title">Batches</div>
          <div className="column-title ">
            Choose <span className="shape-bg">Prefered</span> Batch
          </div>
        </div>
        <div className="flex gap-[30px]">
          <div className="input-container ">
            <select
                className="from-control-status p-4 bg-[#ECECEC] text-[#827878]  "
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                required
            >
                <option key="Scheduled" value="Scheduled">Scheduled</option>
                <option key="InProgress" value="InProgress">InProgress</option>
                <option key="Completed" value="Completed">Completed</option>
                <option key="Cancelled" value="Cancelled">Cancelled</option>
                <option key="Discontinued" value="Discontinued">Discontinued</option>
                
            </select>
            <span className={`input-placeholder ${status && 'active'}`}>
            Status
            </span>
        </div >
      <Link className="btn btn-primary rounded-full  right-0 pl-4" to="/codomo/mentor/addbatch" state={{"batchdata" : batch}}><iconify-icon icon='uiw:usergroup-add' style={{ fontSize: '1em' }} /> Add new batch</Link>
      </div>
      
        <div className="grid lg:grid-cols-4 md:grid-cols-2  grid-cols-1 gap-[30px] pt-10">
          {batch.map((item, index) => (
            item.batchProgress === status ? 
            <Link to="/codomo/mentor/editbatch" state={{"batch" : item}}>
            <div
              className=" rounded-[8px] transition duration-100 hover:shadow-box hover:bg-white group bg-[#FFE8E8]  text-center hover:-translate-y-2 pt-4"
              key={index}
            >
              {/*<div style={{ display: 'flex', justifyContent: 'space-between' }} className="pt-4">
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }} className="pl-4">
            {status === "Scheduled" ?<iconify-icon icon="solar:multiple-forward-left-outline" onClick={()=>UpdateBatchProgress(item,"InProgress")} class="text-secondary " style={{ fontSize: '2em'}}/>
            :status === "InProgress" ?<iconify-icon icon="solar:multiple-forward-left-outline" onClick={()=>UpdateBatchProgress(item,"Completed")} class="text-secondary " style={{ fontSize: '2em' }}/>:""}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }} className="pr-4">
            {status === "Scheduled" ?<iconify-icon icon="material-symbols:cancel-outline" onClick={()=>UpdateBatchProgress(item,"Cancelled")} class="text-primary " style={{ fontSize: '2em' }}/>
            :status === "InProgress" ?<iconify-icon icon="material-symbols:cancel-outline" onClick={()=>UpdateBatchProgress(item,"Discontinued")} class="text-primary " style={{ fontSize: '2em' }}/>:""}
          </div></div>*/}
            
              <div className="w-[72px] h-[72px] rounded-full bg-white relative mx-auto flex flex-col justify-center items-center mb-8 group-hover:bg-[#FFE8E8] ">
              <iconify-icon icon={`${status === 'Completed' ? 'fluent-mdl2:completed-solid' : status === 'InProgress' ? 'grommet-icons:in-progress' : status === 'Scheduled' ? 'mdi:clipboard-text-date' : 'mdi:clipboard-text-date'}`} style={{ fontSize: '2em' }} />

              </div>
              <Tooltip id={item.BatchId} className="bg-secondary">
                <div style={{ display: 'flex', flexDirection: 'column'}}>
                <p>Students :</p><br></br>
                <ul>
                {item.StudentsEndrolled.map((student) => (
                  <li><b>{student.name}</b></li>))}
                  </ul>
                </div>
              </Tooltip>
              <div className="course-content">

                  {item.batchProgress}

                <h4 className=" text-2xl  mb-2 font-bold">{item.courseName}</h4>
                <h4 className=" text-xl ">{item.BatchName.split("-").pop()}</h4>
                <p>{item.StartDate + "  →  " + item.EndDate}</p>
                <p>{item.StartTime + "  →  " + item.EndTime}</p>
              </div>
            </div></Link>: ""
          ))}
        </div>
      </div>
    </div> }
    </> 
  );
};

export default MentorActivities;
