import React,{useEffect,useState} from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import FullCalendar from '@fullcalendar/react' 
import dayGridPlugin from '@fullcalendar/daygrid' 
import timeGridPlugin from '@fullcalendar/timegrid'; 
import interactionPlugin from "@fullcalendar/interaction";
import { FetchCourseDetails} from "../../api/aws-api";
import { ConstructorEventGet } from "../../Event/event";
import { ClassicSpinner } from "react-spinners-kit";
import { useLocation } from "react-router-dom"


const AddBatch = (props) => {
  const location = useLocation();
  const [courseOptions, setCourseOptions] = useState([]);
  const [prevCalculateDateTime, setPrevCalculateDateTime] = useState([]);
  const [startdate, setStartDate] = useState();
  const [enddate, setEndDate] = useState();
  const [showeventmenu, setShoweventmenu] = useState(false);
  const [loading,setLoading] = useState(false)
  const [course, setCourse] = useState();
  const [calenderEventDates,setCalenderEventDates] = useState([])
  const [showAddStudentButton,setShowAddStudentButton] = useState(false)
  const [recurrent, setRecurrent] = useState("Mon-Fri");
  const [coursedata, setCoursedata] = useState([]);
  const [starttime, setStartTime] = useState("09:00");
  const [endtime, setEndTime] = useState("10:00");
  const [prevEvents,setPrevEvents] = useState([])
  const [calenderEvents,setCalenderEvents] = useState([])
  const navigate = useNavigate()

  const [CustomBatchShedule,setCustomBatchShedule] = useState (false)//Custom
  const [Customdate,setCustomDate] = useState();
  const [Customstarttime, setCustomStartTime] = useState("09:00");
  const [Customendtime, setCustomEndTime] = useState("10:00");
  const [Customdatelist,setCustomDateList] = useState([]);



  

  useEffect(() => {
    const {batchdata} = location.state ? location.state : []
    const filteredBatch = batchdata.filter(obj => obj.batchProgress  === "Scheduled" || obj.batchProgress  === "InProgress")
    AcumulatePreviousClassesToCalender(filteredBatch)
    const storedData = sessionStorage.getItem('CourseKey');
    if (storedData !== "undefined" && storedData !== "null" && storedData !== undefined && storedData !== null){setCourseOptions(JSON.parse(storedData))}
  },[])

  const handleDateSelect = (selectInfo) => {
    setStartDate(dateChanger(selectInfo.start))
    setEndDate(recurrent === "Mon-Fri" ? addWorkingDays(dateChanger(selectInfo.start),coursedata.total_class) : addSpecificday(dateChanger(selectInfo.start),coursedata.total_class))
  };

  const dateChanger = (today) =>{
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Add leading zero if necessary
    const day = String(today.getDate()).padStart(2, '0'); 
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  }

  const dayOfWeek = (dateStr) =>{
      const date = new Date(dateStr);
      const dayOfWeekIndex = date.getDay();
      const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const dayName = dayNames[dayOfWeekIndex];
      return dayName
  }

  const AddDurationToTime = (inputTime,inputDuration) =>{
    // Convert input time and duration to hours and minutes
    var [hours, minutes] = inputTime.split(":").map(Number);
    var durationParts = inputDuration.match(/(\d+H)?(\d+M)?/);
    var durationHours = durationParts[1] ? parseInt(durationParts[1]) : 0;
    var durationMinutes = durationParts[2] ? parseInt(durationParts[2]) : 0;
    // Add duration to time
    var newHours = hours + durationHours;
    var newMinutes = minutes + durationMinutes;
    // Handle overflow of minutes
    if (newMinutes >= 60) {
      newHours += Math.floor(newMinutes / 60);
      newMinutes %= 60;
    }
    // Format output
    var outputTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
    return outputTime
  }
  const AcumulatePreviousClassesToCalender = (data) => {
    const totalPrevEvents = []
    const CalculateDateTime = []
    for (let batch = 0; batch < data.length ; batch++){
      if ('ClassSchedule' in data[batch]){
        CalculateDateTime.push( {
          startDate : data[batch].StartDate ,
          endDate : data[batch].EndDate ,
          startTime : data[batch].StartTime,
          endTime : data[batch].EndTime ,
        })
        for (let sheduledclass = 0; sheduledclass < data[batch]["ClassSchedule"].length ; sheduledclass++){
          totalPrevEvents.push(
            {
              title : data[batch]["BatchName"] + "(" + (data[batch]["ClassSchedule"][sheduledclass]["scheduleNumber"]) + ")",
              start : data[batch]["ClassSchedule"][sheduledclass]["startDateTime"],
              end : data[batch]["ClassSchedule"][sheduledclass]["endDateTime"],
              scheduleNumber : data[batch]["ClassSchedule"][sheduledclass]["scheduleNumber"],
            }
          )
        }
      }
    }
    setPrevCalculateDateTime(CalculateDateTime)
    setPrevEvents(totalPrevEvents)
    setCalenderEvents(totalPrevEvents)
  }
  const IncludeInCustomDateList = () => {
    var temp = { 
          startDateTime : Customdate+"T" + Customstarttime + ":00",
          endDateTime : Customdate +"T" + Customendtime + ":00",
          status : "Scheduled",
          meet_link : ""
    }
    const check = doesCustomDateOverlapWithPreviosDate(Customdate,Customstarttime,Customendtime)
    if (!check){setCustomDateList([...Customdatelist,temp])}

  }
  const removeDateFromCustomDateList = (startDateTime) => {
    const updatedList = Customdatelist.filter((item) => item["startDateTime"] !== startDateTime);
    setCustomDateList(updatedList);
  };

  const doesCustomDateOverlapWithPreviosDate = (customdate,startTime,endTime) => {
    const date = new Date(customdate);
    const timeStart = new Date(`1970-01-01T${startTime}`);
    const timeEnd = new Date(`1970-01-01T${endTime}`);
    let flag = false
    for (const data of prevCalculateDateTime) {
      const date2Start = new Date(data.startDate);
      const date2End = new Date(data.endDate);
      const time2Start = new Date(`1970-01-01T${data.startTime}`);
      const time2End = new Date(`1970-01-01T${data.endTime}`);
      if (date <= date2End && date >= date2Start) {
        if (timeStart < time2End && timeEnd > time2Start) {
          flag = true // Ranges overlap
        } 
      }
    }
    return flag
  }

  function doesRangeOverlapWithPreviousData(startDate, endDate, startTime, endTime) {
    const date1Start = new Date(startDate);
    const date1End = new Date(endDate);
    const time1Start = new Date(`1970-01-01T${startTime}`);
    const time1End = new Date(`1970-01-01T${endTime}`);
    let flag = false
    for (const data of prevCalculateDateTime) {
      const date2Start = new Date(data.startDate);
      const date2End = new Date(data.endDate);
      const time2Start = new Date(`1970-01-01T${data.startTime}`);
      const time2End = new Date(`1970-01-01T${data.endTime}`);
      if (date1Start <= date2End && date1End >= date2Start) {
        if (time1Start < time2End && time1End > time2Start) {
          flag = true // Ranges overlap
        } 
      }
    }
    if (flag){console.log("overlaps");setShowAddStudentButton(false)}
    else{console.log("clear");setShowAddStudentButton(true)}
  }



  const fetchCourseDetails = async () => {
    setLoading(true)
    const coursedfulldata = courseOptions.find(obj => obj.course_name  === course);
    const responsedata = await ConstructorEventGet(FetchCourseDetails,{course_id : coursedfulldata.course_id})
    console.log(responsedata)
    setCoursedata(responsedata)
    setStartDate(dateChanger(new Date()))
    setCustomDate(dateChanger(new Date()))
    setEndDate(addWorkingDays(dateChanger(new Date()), responsedata.total_class))
    setEndTime(AddDurationToTime("09:00",responsedata.class_duration))
    setShoweventmenu(true)
    setLoading(false)
  }

  function addWorkingDays(date, numDays) {
    const startDate = new Date(date);
    const endDate = new Date(startDate);
    let count = 1;
    let datelist =[dateChanger(startDate)]
    while (count < numDays) {
      endDate.setDate(endDate.getDate() + 1); // Increment the date by 1 day
      if (endDate.getDay() !== 0 && endDate.getDay() !== 6) {
        // If the day is not a Sunday (0) or Saturday (6)
        datelist.push(dateChanger(endDate))
        count++;
      }
    }
    //console.log(datelist)
    setShowAddStudentButton(false)
    setCalenderEventDates(datelist)
    return endDate.toISOString().split('T')[0]; // Return the date in "yyyy-mm-dd" format
  }

  function addSpecificday(date, DAY) {
    const startDate = new Date(date);
    const endDate = new Date(startDate);
    let count = 1;
    let datelist =[dateChanger(startDate)]
    while (count < DAY) {
      endDate.setDate(endDate.getDate() + 1); // Increment the date by 1 day
      if (endDate.getDay() === startDate.getDay()) {
        // If the day is a Specific day (2)
        datelist.push(dateChanger(endDate))
        count++;
      }
    }
    setShowAddStudentButton(false)
    setCalenderEventDates(datelist)
    return endDate.toISOString().split('T')[0]; // Return the date in "yyyy-mm-dd" format
  }

  const ShowInCalender = (calenderEventDatesforcalender) => {
    doesRangeOverlapWithPreviousData(startdate,enddate,starttime,endtime)
    const coursedfulldata = courseOptions.find(obj => obj.course_name  === course);
    let events = []
    for (let i = 0 ; i < coursedata.total_class ; i++){
      events.push({
        title : coursedfulldata.course_name + "(" + (coursedata.number_of_batches + 1).toString() + ")" + "(" + (i + 1).toString() + ")",
        start : calenderEventDatesforcalender[i] + "T" + starttime + ":00",
        end : calenderEventDatesforcalender[i] + "T" + endtime + ":00",
        scheduleNumber : i,
      })
    }
    setCalenderEvents(events.concat(prevEvents))
    return events
  }
  const ShowdefaultDateInCalender = () => {
    const coursedfulldata = courseOptions.find(obj => obj.course_name  === course);
    let events = []
    for (let i=0; i< Customdatelist.length ; i++){
      events.push({
        title : coursedfulldata.course_name + "(" + (coursedata.number_of_batches + 1).toString() + ")" + "(" + (i + 1).toString() + ")",
        start : Customdatelist[i]["startDateTime"],
        end : Customdatelist[i]["endDateTime"],
        scheduleNumber : parseInt(i)+1,
      })
    }
    setCalenderEvents(events.concat(prevEvents))
    return events
  }
  const setDatainCorrectFormatForStoring = () => {
    const response = ShowInCalender(calenderEventDates)
    let changeddata = []
    for (let i = 0; i < response.length ; i++){
        changeddata.push({
          startDateTime : response[i]["start"],
          endDateTime : response[i]["end"],
          scheduleNumber : response[i]["scheduleNumber"] + 1,
          status : "Scheduled",
          meet_link : ""
        })
    }
    return changeddata
  }
  const setDefaultDatainCorrectFormatForStoring = () => {
    let events = Customdatelist
    for (let i=0; i< Customdatelist.length ; i++){events[i]["scheduleNumber"] = i+1}
    return events
  }

  const NavigatetoStudentAddPage =  () => {
    const changeddata = Customdatelist.length === 0 ? setDatainCorrectFormatForStoring() : setDefaultDatainCorrectFormatForStoring()
    const userdata = JSON.parse(sessionStorage.getItem('UserData'))
    const coursedfulldata = courseOptions.find(obj => obj.course_name  === course);
    const params = {
      mentorId : userdata.user_id,
      BatchName : coursedfulldata.course_name + "-" + coursedfulldata.course_id +"-"+ (coursedata.number_of_batches + 1),
      BatchId : coursedfulldata.course_id +"-"+(coursedata.number_of_batches + 1),
      batchProgress : "Scheduled",
      courseId : coursedfulldata.course_id,
      courseName : coursedfulldata.course_name,
      EndDate : enddate,
      mentorName: userdata.user_name,
      StartDate: startdate,
      StartTime : starttime,
      EndTime : endtime,
      Repeat : recurrent,
      ClassSchedule : changeddata
    }
    navigate("/codomo/mentor/addstudent",{ state: { data: params } })
   }

  return (
    <>
     <Header/>
      <div className="nav-tab-wrapper tabs  section-padding">
        <div className="container">
          <div className=" grid grid-cols-12 gap-[30px]">
            <div className="xl:col-span-5 lg:col-span-6 col-span-12 ">
              <div className="mini-title">New Batch</div>
              <h4 className="column-title ">
                Start a Batch <span className="shape-bg">Now</span>
              </h4>
              <div>
              Enhance your scheduling process by choosing a course and seamlessly scheduling it on the available dates for your batch!
              </div>
              <ul className=" list-item space-y-6 pt-8">
                <li className="flex">
              <div className=" rounded-md w-full">
              { showeventmenu ?                 
                <div className="bg-white shadow-box7 p-8 rounded-md w-full">
                  <div className="fflex-none mr-6 mt-2  focus:ring-0 flex items-center">
                  Course: {course}
                  </div>
                  <div className="fflex-none mr-6 mt-2  focus:ring-0 flex items-center">
                  Total Class : {(coursedata.total_class)}
                  </div>
                  <div className="fflex-none mr-6 mt-2  focus:ring-0 flex items-center">
                  Batch: {(coursedata.number_of_batches + 1)}
                  </div>
                  
                  {CustomBatchShedule ? 
                  <div>
                    <div className="mt-4">
                    {Customdatelist.map((data, index) => (
                      <span key={index} className="email-tag">
                          {data["startDateTime"]}
                          <span className="remove-tag bg-lightgreen" onClick={() => removeDateFromCustomDateList(data["startDateTime"])}>
                          &times;
                          </span>
                      </span>
                      ))}
                      </div>
                    <div className="fflex-none mr-6 mt-4 from-control focus:ring-0 flex items-center">
                      <input value={Customdate} className="from-control-edit focus:ring-0 " required type="date" onChange={(e) => setCustomDate(e.target.value)}/>
                      <input value={Customstarttime} className="from-control-edit focus:ring-0 " required type="time" onChange={(e) => {setCustomStartTime(e.target.value);setCustomEndTime(AddDurationToTime(e.target.value,coursedata.class_duration))}}/>
                      {Customdatelist.length < coursedata.total_class ? <div className="resend-otp" onClick={IncludeInCustomDateList}>Add</div> : ""}
                    </div> 
                    <button className="btn btn-black mt-4 mr-4" onClick={ShowdefaultDateInCalender}>Show In Calender</button>
                    {Customdatelist.length < coursedata.total_class ? "" : <button className="btn btn-primary mt-4" onClick={NavigatetoStudentAddPage}>Add Students</button>}
                  </div>
                  
                  :
                    <div>
                      <div className="fflex-none mr-6 mt-4 from-control focus:ring-0 flex items-center">
                          <input value={startdate} className="from-control-edit focus:ring-0 " required type="date" 
                          onChange={(e) => {setStartDate(e.target.value);
                          setEndDate(recurrent === "Mon-Fri" ? addWorkingDays(e.target.value,coursedata.total_class) : addSpecificday(e.target.value,coursedata.total_class))}}/> {' - '}
                          <input value={enddate} className="from-control-edit focus:ring-0 " required type="date" onChange={(e) => setEndDate(e.target.value)}/>
                      </div>
                      <div className="fflex-none mr-6 mt-4 from-control focus:ring-0 flex items-center">
                          <input value={starttime} className="from-control-edit focus:ring-0 " required type="time" onChange={(e) => {setStartTime(e.target.value);setEndTime(AddDurationToTime(e.target.value,coursedata.class_duration))}}/> {' - '}
                          <input value={endtime} className="from-control-edit focus:ring-0 " required type="time" onChange={(e) => setEndTime(e.target.value)}/>
                      </div> 
                      <div className="flex-none mr-6 mt-4 from-control focus:ring-0 flex items-center" >
                        <select
                            className="from-control-edit focus:ring-0"
                            value={recurrent}
                            onChange={(e) => {setRecurrent(e.target.value);setEndDate(e.target.value === "Mon-Fri" ? addWorkingDays(startdate,coursedata.total_class) : addSpecificday(startdate,coursedata.total_class))}}
                            required>
                            
                            <option value="Mon-Fri">Every Weekday (Mon - Fri)</option>
                            <option value="specific">Weekly on {dayOfWeek(startdate)}</option>
                        </select>
                      </div>
                      <div className="resend-otp mt-4" onClick={()=>setCustomBatchShedule(true)}>Shedule Batch on Custom dates</div>
                    <button className="btn btn-black mt-4 mr-4" onClick={()=>ShowInCalender(calenderEventDates)}>Show In Calender</button>
                    {showAddStudentButton ? <button className="btn btn-primary mt-4" onClick={NavigatetoStudentAddPage}>Add Students</button> : ""}
                  </div>
                  }
                </div>
                :
                <div className="flex-none mr-6 from-control-batch focus:ring-0 flex items-center" >
                  <select
                      className="from-control-batch-edit focus:ring-0"
                      value={course}
                      onChange={(e) => setCourse(e.target.value)}
                      required>
                        <option value="">Select Course</option>
                        {courseOptions.map((item,index)=><option value={item.course_name}>{item.course_name}</option>)}
                  </select>
                   {loading ?
                        <ClassicSpinner size={25} color ="grey" loading={loading} /> 
                      :<iconify-icon icon="material-symbols:arrow-circle-right-outline" style={{ fontSize: '2em' }}  onClick={course && fetchCourseDetails}/>
                   }
                </div> }
                </div>
                </li>
              </ul>
            </div>
              <div className="xl:col-span-7 lg:col-span-6 col-span-12">
                <div className="bg-white shadow-box7 p-8 rounded-md">
                  <FullCalendar
                    plugins={[ dayGridPlugin, timeGridPlugin,interactionPlugin ]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                      center: "dayGridMonth,timeGridWeek",
                    }}
                    weekends={true}
                    selectable={true}
                    select={(clickInfo) => handleDateSelect(clickInfo)}
                    dayMaxEvents={0}
                    events={calenderEvents}
                  />
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBatch;
