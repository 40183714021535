import React, { useState, useRef } from "react";
import "../../assets/css/newacitivity.css";

export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();

  const [questionNumber, setQuestionNumber] = useState(0);
  const [Activitystarted, setActivitystarted] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [SelectedScreen, setSelectedScreen] = useState("");
  const [ClickedNext, setClickedNext] = useState(false);
  const [dragging, setDragging] = useState(true);
  const [divtochangeContent, setDivtochangeContent] = useState(false);
  const [ValueDropdown, setValueDropdown] = useState("");
  const [selectedColorbutton, setSelectedColorbutton] = useState("red");
  const [selectedpopup, setSelectedpopup] = useState(false);

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  // Handle dropdown change event
  const handleColorChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedColor(event.target.value);
    }
  };
  const handleAttributeChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedAttribute(event.target.value);
    }
  };
  const handleScreenChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedScreen(event.target.value);
    }
  };
  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", e.target.id);
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData("text/plain");
    const droppedItem = document.getElementById(droppedItemId);
    // Update the content of divtochangeContent here based on the drop event
    setDivtochangeContent(true);
    e.target.appendChild(droppedItem);
    setDragging(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const colorMap = {
    Red: "red",
    Green: "green",
    Blue: "blue",
    Yellow: "yellow",
  };
  const screenMap = {
    one: "../images/NewActivityImages/mobile3.jpg",
    two: "../images/NewActivityImages/mobile4.jpg",
    three: "../images/NewActivityImages/mobile5.jpg",
    four: "../images/NewActivityImages/Project.jpg",
  };
  const labelMap = {
    "16px": "red",
    "16px": "green",
    "20px": "blue",
    "24px": "yellow",
    "16px": "yellow",
  };

  const divStyle = {
    backgroundColor:
      selectedColorbutton == "Screen" &&
      selectedColor == "background_color" &&
      Activitystarted
        ? ValueDropdown
        : "white",
    height: "121.5%", // Inherit the height from the parent
    width: "111%",
    borderRadius: "30px",
    marginLeft: "-11px",
    marginTop: "-45px",
    border: selectedpopup ? null : "3px solid black",
    display: "flex",
  };
  const data = {
    Screen: {
      background_color: ["red", "blue", "green"],
    },
    Buttons: {
      "button Color": ["red", "blue", "green"],
    },

    Button1: {
      "button Color": ["red", "blue", "green"],
    },
    Button2: {
      "button Color": ["red", "blue", "green"],
    },
  };

  function handleHtmlCode() {
    const data = getHtmlCode.current.outerHTML;
    console.log("The extracted HTML code is----", data);
    setHtmlCode(data);
  }

  return (
    <div class="container" style={{ overflow: "hidden" }}>
      <div></div>
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="Newbody">
            <div className="Newheader">
              <div className="design-vew22" ref={getHtmlCode}>
                <div className="toolboxcss" style={divStyle}>
                  <button
                    className="buttonsessionActivity3"
                    style={{
                      backgroundColor:
                        selectedColorbutton === "Button1" && Activitystarted
                          ? selectedColor == "button Color"
                            ? ValueDropdown
                            : "white"
                          : selectedColorbutton == "Buttons" &&
                            selectedColor == "button Color" &&
                            Activitystarted
                          ? ValueDropdown
                          : "white",
                    }}
                  >
                    Make me Red!
                  </button>
                  <button
                    className="buttonsessionActivity3"
                    style={{
                      backgroundColor:
                        selectedColorbutton === "Button2" && Activitystarted
                          ? selectedColor == "button Color"
                            ? ValueDropdown
                            : "white"
                          : selectedColorbutton == "Buttons" &&
                            Activitystarted &&
                            selectedColor == "button Color"
                          ? ValueDropdown
                          : "white",
                    }}
                  >
                    Make me Blue!
                  </button>
                </div>
              </div>
              {/* <div class="nav-controls2 d-flex justify-content-between align-items-center"> */}
              <button
                className="button-12 mt-2"
                style={{ textAlign: "center" }}
                onClick={() =>
                  Activitystarted
                    ? (setClickedNext(false), setActivitystarted(false))
                    : setActivitystarted(true)
                }
              >
                {Activitystarted ? "RESET" : "RUN"}
              </button>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div class="lg:col-span-9 col-span-12 mt-5">
          <i class="fa-duotone fa-up-down-left-right fa-spin fa-spin-reverse"></i>
          <div className="NavbarApp">&emsp;Instructions</div>
          <div>
            <p>
              <strong>Goal:</strong> Make the buttons "red" and "blue". Then
              change the text in the large orange label.{" "}
            </p>
            <p>
              <strong>How:</strong> Remember you can hover over elements to see
              their "id". Set the "background-color" of the buttons to "red" and
              "blue".
            </p>
          </div>

          <div className=" row ">
            <div className="col-6 ">
              <div class="box">
                <div class="box-head">
                  &emsp;Toolbox
                  <a class="yellow-btn">
                    <i class="fa-solid fa-minus"></i>
                  </a>
                  <iconify-icon
                    icon="icon-park-twotone:right-c"
                    width="20"
                    class="iconify-icon-right"
                  ></iconify-icon>
                  <iconify-icon
                    icon="ant-design:setting-outlined"
                    width="20"
                    class="iconify-icon-left"
                  ></iconify-icon>
                  {}
                </div>
              </div>

              <div class="box-content p-3">
                <div class="controlhead">UI Controls</div>
                <div>&emsp;</div>
                <div onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                  <div
                    class={selectedpopup ? null : "pin"}
                    draggable="true"
                    id="1"
                  >
                    <div
                      class="divtochange flex"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      setProperty{!divtochangeContent && "(id,property,value)"}
                      {divtochangeContent && (
                        <div>
                          (
                          <select
                            class="selectinpin"
                            onChange={(e) =>
                              setSelectedColorbutton(e.target.value)
                            }
                            value={selectedColorbutton}
                          >
                            <option value="">Select a Option</option>
                            {data &&
                              Object.keys(data).map((item, index) => (
                                <option value={item}>{item}</option>
                              ))}
                          </select>
                          <select
                            class="selectinpin"
                            onChange={(e) => setSelectedColor(e.target.value)}
                            value={selectedColor}
                          >
                            <option value="">Select an Option</option>
                            {data &&
                              data[selectedColorbutton] &&
                              Object.keys(data[selectedColorbutton]).map(
                                (Screen, index) => (
                                  <option value={Screen}>{Screen}</option>
                                )
                              )}
                          </select>
                          <select
                            class="selectinpin"
                            onChange={(e) => setValueDropdown(e.target.value)}
                            value={ValueDropdown}
                          >
                            <option value=""></option>
                            {data &&
                              data[selectedColorbutton] &&
                              data[selectedColorbutton][selectedColor] &&
                              data[selectedColorbutton][selectedColor].map(
                                (item1, index) => (
                                  <option value={item1}>{item1}</option>
                                )
                              )}
                          </select>
                          )
                        </div>
                      )}
                    </div>
                  </div>
                  {}
                </div>
              </div>
            </div>
            <div className="col-9">
              <div class="box">
                <div class="box-head">
                  &emsp;Workspace
                  <a
                    class="btn "
                    onClick={() => {
                      popupvalue();
                      handleHtmlCode();
                    }}
                  >
                    <button>Show Code</button>
                  </a>{" "}
                  <a
                    class="btn me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#show-code-popup"
                  >
                    <button>Version History</button>
                  </a>
                  {selectedpopup && (
                    <div className="modal">
                      <div className="overlay">
                        <div className="modal-content">
                          {htmlCode ? htmlCode : null}
                          <button
                            className="btn"
                            style={{ marginTop: "20px" }}
                            onClick={popupvalue}
                          >
                            close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  class="box-content p-3"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div class="plug-board work-space" id="drop-zone"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appone;
