/* eslint-disable jsx-a11y/anchor-is-valid */
import { Disclosure, Tab } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { ClassicSpinner } from "react-spinners-kit";
import { CourseCloudFrontDistributionID } from "../../api/aws-api";
import { useNavigate } from "react-router-dom";
import {
  author,
  camera,
  fb,
  file2,
  headphone,
  instra,
  laptop,
  likeIcon,
  ln,
  lock,
  Anglofone_CourseImage,
  starIcon,
  target,
  twitter,
  user,
  user2,
  ux,
  yt,
  rc1,
  rc2,
  rc3,
  clockIcon,
  cmnt1,
  dymmyUser,
} from "../../constant/images";
import Course_Review from "./Course_Review";

const CourseDetails = (props) => {
  const [showReviewTab, setShowReviewTab] = useState(false);
  const navigate = useNavigate();
  const calculateTotalReview = () => {
    if (
      props.CourseDetails !== undefined &&
      props.CourseDetails.course_star !== undefined
    ) {
      let total_starvalue = 0;
      for (let i = 1; i < 6; i++) {
        total_starvalue =
          total_starvalue + props.CourseDetails.course_star[i] * parseInt(i);
      }
      const percentage = (
        total_starvalue / parseInt(props.CourseDetails.course_star["total"])
      ).toFixed(1);
      return percentage;
    }
  };
  const totalreview = calculateTotalReview();

  const handleJoinNow = () => {
    const meetLink =
      props.courseProps && props.courseProps.next_class_details.meet_link;
    console.log(meetLink, "lommk");
    window.open(meetLink, "_blank"); // Open the meet link in a new tab
  };

  const HandleActivityNavigation = (resource) => {
    console.log(resource.url);
    if (resource.url === "/codomo/codeeditor") {
      navigate("/codomo/codeeditor", {
        state: { resource: resource, CourseProps: props.courseProps },
      });
    } else if (resource.url === "/codomo/AcitivityCourse") {
      navigate("/codomo/AcitivityCourse", {
        state: { resource: resource, CourseProps: props.courseProps },
      });
    } else if (resource.url === "/codomo/NewAcitivityCourse") {
      navigate("/codomo/NewAcitivityCourse");
    } else if (resource.url.includes("onecompiler.com")) {
      // navigate("/codomo/onecompiler",{state: {"Url" : resource.url}})
      sessionStorage.setItem("OneCompilerUrl", resource.url);
      const newTab = window.open("", "_blank");

      // Navigate in the new tab or window
      newTab.location = "/codomo/onecompiler";
    } else if (resource.url.includes("trinket.io")) {
      // navigate("/codomo/onecompiler",{state: {"Url" : resource.url}})
      sessionStorage.setItem("OneCompilerUrl", resource.url);
      const newTab = window.open("", "_blank");

      // Navigate in the new tab or window
      newTab.location = "/codomo/onecompiler";
    } else {
      window.open(resource.url, "_blank", "noopener noreferrer");
    }
  };

  return props.loading ? (
    <div className="spinner">
      <div className="spinner-wrapper">
        <ClassicSpinner size={50} color="black" loading={props.loading} />
      </div>
    </div>
  ) : (
    <div className="nav-tab-wrapper tabs  section-padding">
      <div className="container">
        <div className="grid grid-cols-12 gap-[30px]">
          <div className="lg:col-span-8 col-span-12">
            <div className="single-course-details">
              {/* <div className="xl:h-[470px] h-[350px] mb-10 course-main-thumb">
                <img
                  src={props.CourseDetails ? CourseCloudFrontDistributionID +"/" + props.CourseDetails.course_id + "/images/" + props.CourseDetails.course_image  : Anglofone_CourseImage}
                  alt=""
                  className=" rounded-md object-fut w-full h-full block"
                />
              </div> */}
              <div className=" mb-6">
                <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white ">
                  {props.CourseDetails
                    ? props.CourseDetails.course_catogory
                    : "course_catogory"}
                </span>
              </div>
              <h2>
                {props.CourseDetails
                  ? props.CourseDetails.course_name
                  : "course_name"}
              </h2>
              <Tab.Group>
                <div className="nav-tab-wrapper mt-12">
                  <Tab.List as="ul" id="tabs-nav" className="course-tab mb-8">
                    {["Curriculum", "Mentor", "Reviews"].map((item, index) => (
                      <Tab
                        as="li"
                        key={index}
                        className={({ selected }) =>
                          selected ? "active" : null
                        }
                      >
                        <a href={`#tab`}>{item}</a>
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels id="tabs-content">
                    <Tab.Panel id="tab2" className="tab-content">
                      <div>
                        <h3 className=" text-2xl">Course Description</h3>
                        <div className="md:flex md:space-x-10  space-x-3 flex-wrap mt-4 mb-6">
                          <span>
                            {props.CourseDetails
                              ? props.CourseDetails.description
                              : "description"}
                          </span>
                        </div>
                        <span>
                          <h5>
                            Lectures Total:{" "}
                            {props.CourseDetails
                              ? props.CourseDetails.duration
                              : "description"}
                          </h5>
                        </span>
                        <ul className="list  course-accrodain space-y-6">
                          {props.CourseDetails
                            ? props.CourseDetails.modules.map(
                                (module, index) => (
                                  <Disclosure as="li">
                                    {({ open }) => (
                                      <li
                                        className={open ? "open" : ""}
                                        key={index}
                                      >
                                        <Disclosure.Button
                                          as="button"
                                          className="accrodain-button"
                                        >
                                          <span className="icon-pm fle x-none"></span>
                                          <span className=" flex-1">
                                            {module.module_name}
                                          </span>
                                          <div className="flex-none extra-text  hidden sm:block">
                                            {module.Lectures} Lectures,{" "}
                                            {module.TotalDurationInMinutes} min
                                          </div>
                                        </Disclosure.Button>
                                        <Disclosure.Panel>
                                          <div className="content">
                                            <div className=" text-xl font-semibold text-black mb-2">
                                              {module.description_head}
                                            </div>
                                            <p>{module.description}</p>
                                            <div className=" text-xl font-semibold text-black mb-2 mt-6">
                                              {module.resource_count
                                                ? module.resource_count.video
                                                  ? module.resource_count
                                                      .video + " Videos  "
                                                  : ""
                                                : ""}
                                              {module.resource_count
                                                ? module.resource_count.audio
                                                  ? module.resource_count
                                                      .audio + " Audio  "
                                                  : ""
                                                : ""}
                                              {module.resource_count
                                                ? module.resource_count.activity
                                                  ? module.resource_count
                                                      .activity + " Activity  "
                                                  : ""
                                                : ""}
                                            </div>
                                            <div className=" mt-8 ">
                                              {module.resource.map(
                                                (resource, index) => (
                                                  <div
                                                    className="flex items-start pb-4 mb-4 last:mb-0 last:pb-0 border-b border-[#ECECEC] last:border-0"
                                                    key={index}
                                                    onClick={() =>
                                                      HandleActivityNavigation(
                                                        resource
                                                      )
                                                    }
                                                  >
                                                    <div className="flex-1 flex">
                                                      <span className="flex-none mr-2">
                                                        <img
                                                          src={
                                                            resource.type ===
                                                            "Video"
                                                              ? camera
                                                              : resource.type ===
                                                                "Audio"
                                                              ? headphone
                                                              : resource.type ===
                                                                "Activity"
                                                              ? laptop
                                                              : ""
                                                          }
                                                          alt=""
                                                        />
                                                      </span>
                                                      <span className="flex-1">
                                                        {" "}
                                                        {resource.type}:{" "}
                                                        {resource.description}{" "}
                                                      </span>
                                                    </div>
                                                    <div className="flex-none flex space-x-3">
                                                      {resource.preview ? (
                                                        <span className="bg-primary text-white px-3 rounded">
                                                          Preview
                                                        </span>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {resource.locked ? (
                                                        <img
                                                          src={lock}
                                                          alt=""
                                                        />
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </Disclosure.Panel>
                                      </li>
                                    )}
                                  </Disclosure>
                                )
                              )
                            : " error"}
                        </ul>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel id="tab3" className="tab-content">
                      <div className=" bg-[#F8F8F8] rounded-md p-8">
                        <div className="md:flex space-x-5 mb-8">
                          <div className="h-[310px] w-[270px] flex-none rounded mb-5 md:mb-0">
                            <img
                              src={dymmyUser}
                              alt=""
                              className=" w-full h-full object-cover  rounded"
                            />
                          </div>
                          <div className="flex-1">
                            <div className="max-w-[300px]">
                              <h4 className=" text-[34px] font-bold leading-[51px]">
                                {props.courseProps &&
                                  props.courseProps.batch_mentor_name}
                              </h4>
                              <div className=" text-primary mb-6">
                                Course Mentor
                              </div>
                              <ul className=" list space-y-4">
                                <li className=" flex space-x-3">
                                  <img src={file2} alt="" />
                                  <div>3 Courses</div>
                                </li>

                                <li className=" flex space-x-3">
                                  <img src={user2} alt="" />
                                  <div>10 Student Learned</div>
                                </li>

                                <li className=" flex space-x-3">
                                  <img src={starIcon} alt="" />
                                  <div>10+ Reviews</div>
                                </li>

                                <li className=" flex space-x-3">
                                  <img src={likeIcon} alt="" />
                                  <div>4.9 Average Rating</div>
                                </li>
                              </ul>
                              {/* <ul className=" flex space-x-3 mt-8">
                                <li className="">
                                  <a >
                                    <img src={fb} alt="" />
                                  </a>
                                </li>

                                <li className="">
                                  <a >
                                    <img src={ln} alt="" />
                                  </a>
                                </li>

                                <li className="">
                                  <a >
                                    <img src={yt} alt="" />
                                  </a>
                                </li>

                                <li className="">
                                  <a >
                                    <img src={instra} alt="" />
                                  </a>
                                </li>

                                <li className="">
                                  <a >
                                    <img src={twitter} alt="" />
                                  </a>
                                </li>
                                          </ul>*/}
                            </div>
                          </div>
                        </div>
                        <p>
                          There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration
                          in some form, by injected humour, or randomised words
                          which don't look even slightly believable. If you are
                          going to use a passage of Lorem Ipsum, you need to be
                          sure there isn't anything embarrassing hidden in the
                          middle of text. All the Lorem Ipsum generators on the
                          Internet tend.
                        </p>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel as="div" id="tab4" className="tab-content">
                      <div>
                        <div className="grid grid-cols-12 gap-5">
                          <div className="md:col-span-8 col-span-12">
                            {[5, 4, 3, 2, 1].map((Total, index) => (
                              <div
                                className="flex items-center space-x-4  mb-5 last:mb-0 "
                                key={index}
                              >
                                <div className="flex-none">
                                  <div className="flex space-x-1 text-xl  ">
                                    {[1, 2, 3, 4, 5].map(
                                      (individual, index1) => (
                                        <Fragment key={index1}>
                                          <iconify-icon
                                            icon="heroicons:star-20-solid"
                                            class={
                                              Total - individual >= 0
                                                ? "text-tertiary"
                                                : "text-[#E6E6E6]"
                                            }
                                          ></iconify-icon>
                                        </Fragment>
                                      )
                                    )}
                                  </div>
                                </div>
                                <div className="flex-1">
                                  <div className="progressbar-group flex items-center space-x-4">
                                    <div className="rounded-[2px] overflow-hidden bg-opacity-10 bg-black h-[6px] relative flex-1">
                                      <div
                                        className="ani  h-[6px] bg-secondary block absolute left-0 top-1/2 -translate-y-1/2 "
                                        style={{
                                          width: props.CourseDetails
                                            ? (props.CourseDetails.course_star[
                                                Total
                                              ] /
                                                props.CourseDetails.course_star[
                                                  "total"
                                                ]) *
                                                100 !==
                                              Infinity
                                              ? (
                                                  (props.CourseDetails
                                                    .course_star[Total] /
                                                    props.CourseDetails
                                                      .course_star["total"]) *
                                                  100
                                                ).toFixed(2) + "%"
                                              : "0%"
                                            : "",
                                        }}
                                      ></div>
                                    </div>
                                    <div className="flex-none">
                                      <span className=" block mb-2  font-semibold">
                                        {props.CourseDetails
                                          ? (props.CourseDetails.course_star[
                                              Total
                                            ] /
                                              props.CourseDetails.course_star[
                                                "total"
                                              ]) *
                                              100 !==
                                            Infinity
                                            ? (
                                                (props.CourseDetails
                                                  .course_star[Total] /
                                                  props.CourseDetails
                                                    .course_star["total"]) *
                                                100
                                              ).toFixed(2) + "%"
                                            : "0%"
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="md:col-span-4 col-span-12">
                            <div className="bg-white min-h-[219px] p-6 flex flex-col justify-center items-center shadow-box7 rounded space-y-3">
                              <h2>{totalreview}</h2>
                              <div className="flex space-x-3">
                                {[1, 2, 3, 4, 5].map((individual, index) => (
                                  <iconify-icon
                                    icon="heroicons:star-20-solid"
                                    class={
                                      individual - totalreview > 0
                                        ? "text-[#E6E6E6]"
                                        : "text-tertiary"
                                    }
                                    key={index}
                                  ></iconify-icon>
                                ))}
                              </div>
                              <span className=" block">
                                (
                                {props.CourseDetails
                                  ? props.CourseDetails.course_star["total"]
                                  : ""}{" "}
                                Review)
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className=" mt-8">
                          <h4 className=" text-xl font-bold text-black">
                            Reviews
                          </h4>
                          <ul className=" list space-y-6 mt-6">
                            {props.CourseDetails
                              ? props.CourseDetails.course_review.map(
                                  (Review, index) =>
                                    Review.status === true && (
                                      <li
                                        className=" flex space-x-6 "
                                        key={index}
                                      >
                                        <div className="flex-none">
                                          <div className="h-[72px] w-[72px] rounded-full">
                                            <img
                                              src={cmnt1}
                                              alt=""
                                              className=" object-cover w-full h-full"
                                            />
                                          </div>
                                        </div>
                                        <div className="flex-1">
                                          <div className="flex space-x-3 mb-4">
                                            {[1, 2, 3, 4, 5].map(
                                              (star, index1) => (
                                                <Fragment key={index1}>
                                                  <iconify-icon
                                                    icon="heroicons:star-20-solid"
                                                    class={
                                                      star - Review.star > 0
                                                        ? "text-[#E6E6E6]"
                                                        : "text-tertiary"
                                                    }
                                                  ></iconify-icon>
                                                </Fragment>
                                              )
                                            )}
                                          </div>
                                          <p>{Review.message}</p>
                                          <div className="author mt-4">
                                            <span className="block text-xl font-bold text-black">
                                              {Review.reviewer_name}
                                            </span>
                                            <span className="block">
                                              {Review.date}
                                            </span>
                                          </div>
                                        </div>
                                      </li>
                                    )
                                )
                              : ""}
                          </ul>
                          {showReviewTab ? (
                            <Course_Review
                              setShowreviewtab={setShowReviewTab}
                            />
                          ) : (
                            <span
                              className="review-red"
                              onClick={() => setShowReviewTab(true)}
                            >
                              Add A Review
                            </span>
                          )}
                        </div>
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </div>
              </Tab.Group>
            </div>
          </div>
          <div className="lg:col-span-4 col-span-12">
            <div className="sidebarWrapper space-y-[30px]">
              <div className="wdiget custom-text space-y-5 ">
                <a className="h-[220px] rounded relative block" href="#">
                  <iframe
                    src={`https://www.youtube.com/embed/${
                      props.CourseDetails
                        ? props.CourseDetails.PreviewVedioId
                        : ""
                    }`} // Replace {VIDEO_ID} with the actual YouTube video ID
                    title="YouTube Video"
                    allowFullScreen
                    className="w-full h-full absolute top-0 left-0"
                  ></iframe>
                </a>
                <h3>Upcomming Session</h3>
                {props.courseProps && props.courseProps.next_class_details && (
                  <button
                    className="btn btn-primary w-full text-center "
                    onClick={handleJoinNow}
                  >
                    Join Now
                  </button>
                )}
                <ul className="list  ">
                  <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                    <div className="flex-1 space-x-3 flex">
                      <img src={user} alt="" />
                      <div className=" text-black font-semibold">
                        Instructor
                      </div>
                    </div>
                    <div className="flex-none">
                      {props.courseProps && props.courseProps.batch_mentor_name}
                    </div>
                  </li>

                  <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                    <div className="flex-1 space-x-3 flex">
                      <img src={file2} alt="" />
                      <div className=" text-black font-semibold">Lectures</div>
                    </div>
                    <div className="flex-none">
                      {props.CourseDetails ? props.CourseDetails.lectures : ""}
                    </div>
                  </li>

                  <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                    <div className="flex-1 space-x-3 flex">
                      <img src={clockIcon} alt="" />
                      <div className=" text-black font-semibold">Duration</div>
                    </div>
                    <div className="flex-none">
                      {props.CourseDetails ? props.CourseDetails.duration : ""}
                    </div>
                  </li>
                  <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                    <div className="flex-1 space-x-3 flex">
                      <img src={target} alt="" />
                      <div className=" text-black font-semibold">
                        Session Schedule
                      </div>
                    </div>
                    <div className="flex-none">
                      {props.courseProps
                        ? props.courseProps.next_class_details
                          ? props.courseProps.next_class_details.startDateTime
                              .split("T")
                              .join(" ")
                          : "No Sheduled Class"
                        : ""}
                    </div>
                  </li>
                </ul>
              </div>

              {/*<div className="wdiget">
                <h4 className=" widget-title">Related Courses</h4>
                <ul className="list">
                  <li className=" flex space-x-4 border-[#ECECEC] pb-6 mb-6 last:pb-0 last:mb-0 last:border-0 border-b">
                    <div className="flex-none ">
                      <div className="h-20 w-20  rounded">
                        <img
                          src={rc1}
                          alt=""
                          className=" w-full h-full object-cover rounded"
                        />
                      </div>
                    </div>
                    <div className="flex-1 ">
                      <div className="flex space-x-3 mb-2">
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                      </div>
                      <div className="mb-1 font-semibold text-black">
                        Greatest Passion In...
                      </div>
                      <span className=" text-secondary font-semibold">
                        $38.00
                      </span>
                    </div>
                  </li>
                  <li className=" flex space-x-4 border-[#ECECEC] pb-6 mb-6 last:pb-0 last:mb-0 last:border-0 border-b">
                    <div className="flex-none ">
                      <div className="h-20 w-20  rounded">
                        <img
                          src={rc2}
                          alt=""
                          className=" w-full h-full object-cover rounded"
                        />
                      </div>
                    </div>
                    <div className="flex-1 ">
                      <div className="flex space-x-3 mb-2">
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                      </div>
                      <div className="mb-1 font-semibold text-black">
                        Greatest Passion In...
                      </div>
                      <span className=" text-secondary font-semibold">
                        $38.00
                      </span>
                    </div>
                  </li>
                  <li
                    className=" flex space-x-4 border-[#ECECEC] pb-6 mb-6 last:pb-0 last:mb-0 
                   last:border-0 border-b"
                  >
                    <div className="flex-none ">
                      <div className="h-20 w-20  rounded">
                        <img
                          src={rc3}
                          alt=""
                          className=" w-full h-full object-cover rounded"
                        />
                      </div>
                    </div>
                    <div className="flex-1 ">
                      <div className="flex space-x-3 mb-2">
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                        <iconify-icon
                          icon="heroicons:star-20-solid"
                          class="text-tertiary"
                        ></iconify-icon>
                      </div>
                      <div className="mb-1 font-semibold text-black">
                        Greatest Passion In...
                      </div>
                      <span className=" text-secondary font-semibold">
                        $38.00
                      </span>
                    </div>
                  </li>
                </ul>
                                  </div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;
