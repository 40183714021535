import React, { useState, useRef } from "react";
import "../../assets/css/newacitivity.css";

export function Appone() {
  const getHtmlCode = useRef();
  const [htmlCode, setHtmlCode] = useState();

  const [Activitystarted, setActivitystarted] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [SelectedScreen, setSelectedScreen] = useState("");
  const [ClickedNext, setClickedNext] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [ValueDropdown, setValueDropdown] = useState("");
  const [InputName, setInputName] = useState("");
  const [InputSize, setInputSize] = useState("");
  const [InputHeight, setInputHeight] = useState("");
  const [InputWidth, setInputWidth] = useState("");
  const [InputColor, setInputColor] = useState("");
  const [InputFontColor, setInputFontColor] = useState("black");
  const [selectedpopup, setSelectedpopup] = useState(false);

  // Handle dropdown change event
  const handleColorChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedColor(event.target.value);
    }
  };
  const handleScreenChange = (event) => {
    console.log(event.target.value);
    if (Activitystarted == true) {
      setSelectedScreen(event.target.value);
    }
  };
  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", e.target.id);
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedItemId = e.dataTransfer.getData("text/plain");
    const droppedItem = document.getElementById(droppedItemId);
    console.log(droppedItemId);
    e.target.appendChild(droppedItem);
    setDragging(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const InputStyle = {
    fontSize: Activitystarted ? parseInt(InputSize) : null,
    backgroundColor: InputColor && Activitystarted ? InputColor : "#87F0F1",
    height: InputHeight && Activitystarted ? parseInt(InputHeight) : 33,
    width: InputWidth && Activitystarted ? parseInt(InputWidth) : 100,
    borderColor: "red",
    color: InputFontColor,
    marginTop: "10px",
    cursor: "pointer",
    borderRadius: "10px",
    marginLeft: "5px",
  };

  function handleHtmlCode() {
    const data = getHtmlCode.current.outerHTML;
    console.log("The extracted HTML code is----", data);
    setHtmlCode(data);
  }

  const popupvalue = () => {
    setSelectedpopup(!selectedpopup);
  };

  return (
    <div class="container" style={{ overflow: "hidden" }}>
      <div className="grid grid-cols-12">
        <div className="lg:col-span-3 col-span-12 ">
          <div className="Newbody">
            <div className="design-vew7" ref={getHtmlCode}>
              <div className="design-vew7box">
                Choose Your Adventure! Do you Want to go LEFT or RIGHT
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
                onDrop={(e) => Activitystarted && handleDrop(e)}
                onDragOver={(e) => Activitystarted && handleDragOver(e)}
              >
                <button
                  className="buttonsession"
                  style={{ backgroundColor: "green" }}
                >
                  Left
                </button>
              </div>

              {/* <div class="box-content p-3 mt-13 ml-20">
                <div
                  class="box-content p-3"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div
                    class=" plug-boardwork-space-height"
                    id="drop-zone"
                  ></div>
                </div>
              </div> */}

              {/* {!ClickedNext ? <div className="mt-14"></div> : ""} */}
            </div>
            <button
              className="button-12 mt-2 "
              style={{ textAlign: "center" }}
              onClick={() =>
                Activitystarted
                  ? (setClickedNext(false), setActivitystarted(false))
                  : setActivitystarted(true)
              }
            >
              {Activitystarted ? "RESET" : "RUN"}
            </button>
          </div>
        </div>

        <div class="lg:col-span-9 col-span-12 mt-5">
          <i class="fa-duotone fa-up-down-left-right fa-spin fa-spin-reverse"></i>
          <div className="NavbarApp">&emsp;Instructions</div>
          <div className="fontsp">Goal:</div>
          <h6>
            &emsp;&emsp;Create a new screen called "rightScreen". Add a text
            label that says "You went right!".
          </h6>
          <div className="fontsp">How:</div>
          <h6>
            &emsp;&emsp; In Design Mode drag in a new screen element. Change its
            "id" to "rightScreen". Then drag a label onto the screen and change
            the text to "You went right!".{" "}
          </h6>

          <div className=" row ">
            <div className="col-6">
              <div class="box">
                <div class="box-head">
                  <h2>&emsp;&emsp;&emsp;TOOLBOX</h2>
                  <a class="yellow-btn">
                    <i class="fa-solid fa-minus"></i>
                  </a>
                  <iconify-icon
                    icon="icon-park-twotone:right-c"
                    width="20"
                    class="iconify-icon-right"
                  ></iconify-icon>
                  <iconify-icon
                    icon="ant-design:setting-outlined"
                    width="20"
                    class="iconify-icon-left"
                  ></iconify-icon>
                </div>
              </div>

              <div class="box-content p-3 Toolboxmaxwidth">
                <ul class="" role="tablist">
                  <li class="nav-item">
                    <i class="fa-regular fa-gear"></i>
                    <a class="ybox" data-bs-toggle="tab" href="#control">
                      Design the Elements
                    </a>
                  </li>
                </ul>
                <hr></hr>
                <hr></hr>
                <hr></hr>
                <div onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                  <div class=" draggable" draggable="true" id="2">
                    <div class="box-content p-3">
                      {/* <button style={  } id='button1' draggable="true" >{ButtonName}</button> */}
                      <input
                        style={InputStyle}
                        id="Input1"
                        draggable="true"
                        value={InputName}
                        onChange={(e) => setInputName(e.target.value)}
                      ></input>
                      <div class="">
                        <iconify-icon
                          icon="vaadin:button"
                          width="60"
                          id="1"
                          draggable="true"
                        ></iconify-icon>
                        <iconify-icon
                          icon="lucide:text-cursor-input"
                          width="60"
                          id="2"
                          draggable="true"
                        ></iconify-icon>
                        <iconify-icon
                          icon="material-symbols:abc"
                          width="60"
                          id="3"
                          draggable="true"
                        ></iconify-icon>
                        <iconify-icon
                          icon="fluent-mdl2:dropdown"
                          width="60"
                          id="d4"
                          draggable="true"
                        ></iconify-icon>
                        <iconify-icon
                          icon="radix-icons:radiobutton"
                          width="60"
                          id="drag5gable-item1"
                          draggable="true"
                        ></iconify-icon>
                        <iconify-icon
                          icon="tabler:checkbox"
                          width="60"
                          id="draggable6-item1"
                          draggable="true"
                        ></iconify-icon>
                        {/* <iconify-icon icon="carbon:image" width="60" id='draggable-7item1' draggable="true"></iconify-icon> */}
                        {/* <iconify-icon icon="mdi:canvas" width="60" id='draggable-8item1' draggable="true"></iconify-icon> */}
                        {/* <iconify-icon icon="fa:mobile" width="60" id='dragga9ble-item1' draggable="true"></iconify-icon> */}
                        {/* <iconify-icon icon="bi:textarea" width="60" id='draggable-ite10m1' draggable="true"></iconify-icon> */}
                        {/* <iconify-icon icon="solar:chart-linear" width="60" id='dragga11ble-item1' draggable="true"></iconify-icon> */}
                        {/* <iconify-icon icon="bx:slider-alt" width="60" id='dragga12ble-item1' draggable="true"></iconify-icon> */}
                        {/* <iconify-icon icon="clarity:camera-solid" width="60" id='drag13gable-item1' draggable="true"></iconify-icon> */}

                        {/* <div class="plug-blue" ondrop="drop(event)" ondragover="allowDrop(event)"> */}

                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5 ">
              <div class="box">
                <div class="box-head">
                  <h2>WORKSPACE</h2>
                  <a
                    class="btn mt-5 "
                    onClick={() => {
                      popupvalue();
                      handleHtmlCode();
                    }}
                  >
                    <button>Show Code</button>
                  </a>
                  {"  "}
                  <a
                    class="btn mt-5"
                    data-bs-toggle="modal"
                    data-bs-target="#show-code-popup"
                  >
                    <button>Version History</button>
                  </a>
                  {selectedpopup && (
                    <div className="modal">
                      <div className="overlay">
                        <div className="modal-content">
                          {htmlCode ? htmlCode : null}
                          <button
                            className="btn"
                            style={{ marginTop: "30px" }}
                            onClick={popupvalue}
                          >
                            close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div class="box-content p-3 Workspacemaxwidth">
                  <div class="box-content p-3">
                    <div>
                      <input
                        value={InputSize}
                        type="number"
                        placeholder="Enter the Fontsize "
                        onChange={(e) => setInputSize(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        value={InputHeight}
                        type="number"
                        placeholder="Enter The Height"
                        onChange={(e) => setInputHeight(e.target.value)}
                      ></input>
                    </div>
                    <div>
                      <input
                        type="number"
                        onChange={(e) => setInputWidth(e.target.value)}
                        placeholder="Enter the Width"
                        value={InputWidth}
                      ></input>
                    </div>
                    <select
                      onChange={(e) => setInputColor(e.target.value)}
                      value={InputColor}
                    >
                      <option value="">Select a Color</option>
                      {/* Other dropdown options */}
                      <option value="red">RED</option>
                      <option value="blue">BLUE</option>
                      <option value="green">GREEN</option>
                      <option value="yellow">YELLOW</option>
                    </select>
                    <select
                      onChange={(e) => setInputFontColor(e.target.value)}
                      value={InputFontColor}
                    >
                      <option value="">Select an option</option>
                      {/* Other dropdown options */}
                      <option value="white">WHITE</option>
                      {/* <option value="C7ED1A">NEON</option> */}
                      <option value="#DB3BEE">VOILET</option>
                      <option value="#605C61 ">GREY</option>
                    </select>

                    <div class="plug-board work-space"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appone;
