import React,{useRef,useState,useEffect} from "react";
import Header from "../Header";
import { useLocation,useNavigate } from "react-router-dom"
import { StudentCloudFrontDistributionID,UploadProfileImageToS3 } from "../../api/aws-api";
import { ConstructorEventPost } from "../../Event/event";
import axios from 'axios';
import { CircleSpinner } from "react-spinners-kit";
import { toast } from 'react-toastify';

//


const EditProfile = (props) => {
    const fileInputRef = useRef(null)
    const location = useLocation();
    const [profileImage, setProfileImage] = useState(null);
    const [loading,setLoading] = useState(false)
    const [editAddressMenu,setEditAddressMenu] = useState(false)
    const [userdata,setUserData] = useState()
    const [address,setAddress] = useState("")
    const navigate = useNavigate()
    useEffect(()=>{
        const {data} = location.state ? location.state : userdata ? userdata : []
        setUserData(data)
        console.log(data)
    },[])

    const notify = (message) => toast.success(message, {
      position: "top-left",
      autoClose: 3000,
      hideProgressBar: false,
      pauseOnHover: true,
      progress: undefined,
      theme: "dark",
      });;

    const handleProfileUpload = async(e) => {
      setLoading(true)
        const files = e.target.files;
        setProfileImage(files[0]);
        console.log(files[0])
        //const reader = new FileReader();
        //reader.readAsBinaryString(files[0]);
        //reader.onload = async() => {
        //const binaryStr = reader.result;
        const responsedata = await ConstructorEventPost(UploadProfileImageToS3,{key: userdata.user_id,imgtype : files[0].type})
        uploadImage(responsedata,files[0]).then((response) => {
          console.log('Image uploaded successfully:', response);
        })
        .catch((error) => {
          console.error('Error uploading image:', error);
        })
        console.log(responsedata,"link") // You can use this binary string in your code
        
        //uploadImageToS3(responsedata, files[0])
        //};
        console.log("something")
        
      };
    const uploadImage= async(link,file) => {
      console.log(file.type)
      const responsedata1 = await axios.put(link,file,{
        headers: {
          'Content-Type': file.type,
        },
      }).then(()=>{notify("Profile Image Updated");navigate('/codomo/home')})
      console.log(responsedata1,"response")
      setLoading(false)
    }
    // call the function to upload an image file to S3
    //const fileInput = document.querySelector('input[type="file"]');
    //const file = fileInput.files[0];
    

  return (
    <>
    <Header />
    <div className="section-padding-top-editprofile">
      <div className="container">
        <div className=" pt-10 grid grid-cols-12 xl:gap-0 gap-[30px]">
            <div
              className=" bg-white shadow-box3 rounded-[8px] transition-all duration-100 pt-10 pb-[28px] text-center hover:shadow-box4
                border-t-4 border-transparent hover:border-secondary lg:col-span-3 xl:col-span-3 col-span-12  mb-14"
                >
              <div className="w-[170px] h-[170px] rounded-full  relative mx-auto mb-2">
                <img
                  src={ userdata ? userdata.user_image ? StudentCloudFrontDistributionID +"/studentimages/" + userdata.user_image : StudentCloudFrontDistributionID + "/studentimages/dummyUser.webp" : StudentCloudFrontDistributionID + "/studentimages/dummyUser.webp" }
                  alt=""
                  className=" w-full h-full object-cover rounded-full"
                />
                
              </div>
                <div className=" flex justify-center">
                    <a
                      className=" h-10 w-10 rounded bg-red-paste text-primary flex flex-col justify-center items-center text-2xl transition
                                hover:bg-primary hover:text-white rounded-full"
                    >
                      {loading ? <CircleSpinner size={20} color ="#FF7E84" loading={loading} /> : <iconify-icon icon="material-symbols:cloud-upload" onClick={() => fileInputRef.current.click()} ></iconify-icon> }
                    </a>
                    
                </div>
                <div className="mb-4 mini-title">Upload Your profile</div>
                <hr className="mr-4 ml-4 mb-4"></hr>
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleProfileUpload}
                        />
              <div className="course-content">
                <h4 className=" lg:text-2xl text-1xl mb-1 font-bold">
                {userdata && userdata.user_name }
                </h4>
                <div>{userdata && userdata.user_type }</div>
              </div>
            </div>

            <div className="lg:col-span-9 xl:col-span-8 col-span-12">
                <div className="bg-white shadow-box7 rounded-md ml-4 lg:sticky pl-4 pb-8 mb-8">
                <div className="mini-title pt-8 pl-4">User & Batch Details</div>
                <hr className="ml-2 mr-4 mt-2"></hr>
                <div className="fflex-none mr-6 pt-4 pl-8  focus:ring-0 flex items-center">
                      Email : <b>  {userdata && userdata.user_email }</b> 
                  </div> 
                  <div className="fflex-none mr-6 pt-4 pl-8  focus:ring-0 flex items-center">
                      Phone Number : <b> {userdata && userdata.user_mobile }</b>
                  </div> 
                  <div className="fflex-none mr-6 pt-4 pl-8  focus:ring-0 flex items-center">
                       Parent Name : <b> {userdata && userdata.parent_name } </b>
                  </div>
                  <div className="fflex-none mr-6 pt-4 pl-8  focus:ring-0 flex items-center">
                       Batch : <b> {userdata && userdata.course && userdata.course[0] && userdata.course[0].batch_id}</b>
                  </div>  
                  <div className="fflex-none mr-6 pt-4 pl-8 focus:ring-0 flex items-center">
                      Mentor : <b> {userdata && userdata.course && userdata.course[0] && userdata.course[0].batch_mentor_name }</b>
                  </div>
                  {editAddressMenu ?
                  <div className="input-container ml-8 mt-4 mr-10">
                    <input
                      type="text"
                      className="from-control"
                      placeholder="Address *"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      required
                    />
                  </div>
                  :<div className="fflex-none mr-6 pt-4 pl-8  focus:ring-0 flex items-center">
                  {/*<iconify-icon icon="material-symbols:edit-location-alt-sharp" onClick={() => {setEditAddressMenu(false);setAddress(userdata.user_address)}} ></iconify-icon>*/}Address  :  <b> {userdata && userdata.user_address }</b>
                  </div> }  
                  {editAddressMenu && address!= userdata.user_address ? <button className="btn btn-secondary mt-4 mr-4 ml-8" onClick={()=>setEditAddressMenu}>Save Changes</button> : ""}
                </div>
            </div>
        </div>
      </div>
    </div>
</>
  );
};

export default EditProfile;