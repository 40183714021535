import axios from "axios";
import alertify from "alertifyjs";
import "../../node_modules/alertifyjs/build/css/alertify.css";
import "../../node_modules/alertifyjs/build/css/themes/semantic.css";

const DataFetchFailed = (message) => {
  alertify.set("notifier", "position", "top-right");
  alertify.error("Data fetch Submition Failed", message);
};

export const ConstructorEventPost = async (api, data) => {
  try {
    const response = await axios.post(api, data);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error, "error");
  }
};
//Edit
export const ConstructorEventGet = async (api, data) => {
  //const headers = {'authorizationToken': 'fadhil/fadhil.abdulla.456@gmail.com/G2G1GG378F'}

  try {
    const response = await axios.get(api, { params: data });
    return response.data;
  } catch (error) {
    console.error(error, "error");
    //DataFetchFailed(error.message)
  }
};

export const ConstructorEventGetNoParams = async (api, data) => {
  try {
    const response = await axios.get(api, data);
    return response.data;
  } catch (error) {
    console.error(error);
    //DataFetchFailed(error.message)
  }
};
