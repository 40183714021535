import React, { useState,useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom"
import Header from "../Header";
import { Table,Input } from "antd";
import { AddNewCourse } from "../../api/aws-api";
import { ConstructorEventPost,ConstructorEventGet } from "../../Event/event";
import { ClassicSpinner,MagicSpinner } from "react-spinners-kit";
import { notifySuccess,notifyError } from "../../Common/CommonFunction";
import Swal from 'sweetalert2'

const CreateNewCourse = () => {
    const [loading,setLoading] = useState(false)
    const [ClassDuration,setClassDuration] = useState("")
    const [CourseCategory,setCourseCategory] = useState("")
    const [CourseImage,setCourseImage] = useState("")
    const [CourseName,setCourseName] = useState("")
    const [CourseDescription,setCourseDescription] = useState("")
    const [CourseDuration,setCourseDuration] = useState("")
    const [Lectures,setLectures] = useState("")
    const [PreviewVedioId,setPreviewVedioId] = useState("")
    const [TotalClass,setTotalClass] = useState("")
    const navigate = useNavigate();
       
    const SubmitDataToCloud = async() => {
      if (CourseName !== "" ) {
        setLoading(true)
        const formdata = { 
          "class_duration": ClassDuration,
          "course_catogory": CourseCategory,
          "course_image": CourseImage,
          "course_name": CourseName,
          "course_review": [],
          "course_star": {"1": 0,"2": 0,"3": 0,"4": 0,"5": 0,"total": 0},
          "description": CourseDescription,
          "duration": CourseDuration,
          "last_updated": "28 March, 2023",
          "lectures": parseInt(Lectures),
          "modules": [],
          "number_of_batches": 0,
          "PreviewVedioId": PreviewVedioId,
          "session": [],
          "total_class": TotalClass
        }

        const responsedata = await ConstructorEventPost(AddNewCourse,formdata)
        if (responsedata === "course_registered") {notifySuccess("Course Created SuccessFully");navigate("/codomo/admin/coursemanagement")}
        setLoading(false)
      }
      else {notifyError("Please Enter All Required Fields")}
    }
   

    return (
        <>
          <Header/>
          {loading ? 
          <div className="spinner" >
            <div className="spinner-wrapper">
              <ClassicSpinner size={50} color ="black" loading={loading} />
              </div></div> :
                <div className="nav-tab-wrapper tabs  section-padding">
                  <div className="container">
                    <div className="mini-title">Create New Course</div>
                      <div className=" grid grid-cols-12 gap-[30px] bg-white shadow-box7 p-8 rounded-md mt-8">
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                          <input
                            type="text"
                            className="from-control"
                            value={CourseName}
                            onChange={(e) => setCourseName(e.target.value)}
                            required
                          />
                          <span className={`input-placeholder ${CourseName && 'active'}`}>
                            Course Name *
                          </span>
                        </div>
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                          <input
                            type="text"
                            className="from-control"
                            value={CourseCategory}
                            onChange={(e) => setCourseCategory(e.target.value)}
                            required
                          />
                          <span className={`input-placeholder ${CourseCategory && 'active'}`}>
                          Course Catogory *
                          </span>
                        </div>
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                          <input
                            type="text"
                            className="from-control"
                            value={CourseDescription}
                            onChange={(e) => setCourseDescription(e.target.value)}
                            required
                          />
                          <span className={`input-placeholder ${CourseDescription && 'active'}`}>
                          Course Description *
                          </span>
                        </div>
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                          <input
                            type="text"
                            className="from-control"
                            value={CourseDuration}
                            onChange={(e) => setCourseDuration(e.target.value)}
                            required
                          />
                          <span className={`input-placeholder ${CourseDuration && 'active'}`}>
                            Course Duration *
                          </span>
                        </div>
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                          <input
                            type="text"
                            className="from-control"
                            value={Lectures}
                            onChange={(e) => setLectures(e.target.value)}
                            required
                          />
                          <span className={`input-placeholder ${Lectures && 'active'}`}>
                          lectures *
                          </span>
                        </div>
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                          <input
                            type="text"
                            className="from-control"
                            value={TotalClass}
                            onChange={(e) => setTotalClass(e.target.value)}
                            required
                          />
                          <span className={`input-placeholder ${TotalClass && 'active'}`}>
                          Total Classes *
                          </span>
                        </div>
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                          <input
                            type="text"
                            className="from-control"
                            value={ClassDuration}
                            onChange={(e) => setClassDuration(e.target.value)}
                            required
                          />
                          <span className={`input-placeholder ${ClassDuration && 'active'}`}>
                          Class Duration*
                          </span>
                        </div>
                        <div className="input-container xl:col-span-4 lg:col-span-4 sm:col-span-12 col-span-4">
                          <input
                            type="text"
                            className="from-control"
                            value={PreviewVedioId}
                            onChange={(e) => setPreviewVedioId(e.target.value)}
                            required
                          />
                          <span className={`input-placeholder ${PreviewVedioId && 'active'}`}>
                          Preview Vedio Id (Youtube)*
                          </span>
                        </div>
                        <div className=" mt-8 xl:col-span-5 lg:col-span-5 sm:col-span-12 col-span-5">
                          <button className="btn btn-black" onClick={() => navigate("/codomo/admin/coursemanagement")} >CANCEL</button>
                          <button className="btn ml-4 btn-primary" onClick={SubmitDataToCloud}>SAVE</button>                           
                        </div>        
                  </div>
                </div>
              </div>}

        </>
    )

}
export default CreateNewCourse;